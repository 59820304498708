<jt-side-nav>
    <jt-page-title [title]="'Subject data'"></jt-page-title>
    <div class="container-fluid margin-top-52">
        <div class="row border-bottom rounded">
            <div class="col-6 text-left pt-2 pb-2">
                <span translate>Subject</span> &nbsp; {{subject?.subjectNumber}} &nbsp;
                {{subject?.officialSubjectNumber}}
            </div>
            <div class="col-6 text-right pt-2 pb-2">
                <a *ngIf="!updating && subject?.status!=='ARCHIVED'" (click)="initForm()" href="javascript:void(0)"><i
                        class="fas fa-pencil-alt main-color"></i></a>
                <a *ngIf="!updating && subject?.status!=='ARCHIVED'" data-toggle="modal" data-target="#exampleModal"
                    class="ml-3" href="javascript:void(0)"><i class="fas fa-archive main-color"></i></a>
                <a *ngIf="updating && subject?.status!=='ARCHIVED'" (click)="updating=false"
                    href="javascript:void(0)"><i class="fas fa-times main-color"></i></a>
                <a *ngIf="subject?.status==='ARCHIVED'" data-toggle="modal" data-target="#retrieveModal"
                    href="javascript:void(0)"><i class="fas fa-trash-restore-alt main-color"></i></a>
            </div>
        </div>
    </div>
    <div *ngIf="!updating" class="container p-3 p-md-5">
        <div class="row shadow rounded pt-3 pb-3">
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Client</p>
                <span *ngIf="user && user.type==='INDIVIDUAL'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp;
                    {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp;
                    {{user.jmbg}} &nbsp;&nbsp;</span>
                <span *ngIf="user && user.type==='COMPANY'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp;
                    {{user.companyName}} &nbsp;&nbsp; {{user.mb}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp;
                    {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; </span>
            </div>
            <div class="col-lg-4 border-right">
                <p class="font-weight-bold" translate>Subject number</p>
                <p>{{subject?.subjectNumber}}</p>
                <p class="font-weight-bold" translate>Official subject number</p>
                <p>{{subject?.officialSubjectNumber ? subject.officialSubjectNumber:'/'}}</p>
                <p class="font-weight-bold" translate>Short description</p>
                <p>{{subject?.shortDescription ? subject.shortDescription:"/"}}</p>
                <p class="font-weight-bold" translate>Physical Location</p>
                <p>{{subject?.physicalLocation ? subject.physicalLocation:"/"}}</p>
            </div>
            <div class="col-lg-4 border-right">
                <p class="font-weight-bold" translate>Type of subject</p>
                <p translate>{{subject?.type | translate}}</p>
                <p class="font-weight-bold" translate>Date of subject opening</p>
                <p>{{moment(subject?.dateOpened).format('DD/MM/YYYY')}}</p>
                <p class="font-weight-bold" translate>Date of subject closing</p>
                <p>{{subject?.dateClosed ? moment(subject.dateClosed).format('DD/MM/YYYY'):'/'}}</p>
            </div>
            <div class="col-lg-4">
                <p class="font-weight-bold" translate>Status</p>
                <p>{{subject?.status | translate}}</p>
                <p class="font-weight-bold" translate>Rules and policy PDF</p>
                <p><a target="_blank" [href]="subject?.rulesAndPolicyPDF"><i
                            class="fas fa-file-pdf main-color fa-2x"></i></a></p>
                <p class="font-weight-bold" translate>Contract PDF</p>
                <p *ngIf="subject?.contractPDF"><a target="_blank" [href]="subject?.contractPDF"><i
                            class="fas fa-file-pdf main-color fa-2x"></i></a>
                </p>
            </div>
            <div class="col-12 border-top mt-3 pt-3">
                <p class="font-weight-bold" translate>Subject flow</p>
                <div *ngFor="let step of subject?.flow; let i=index" class="pl-3 border-bottom">
                    <p translate class="font-weight-bold">Step<span> {{i+1}}</span></p>
                    <div class="pl-3 pl-md-5">
                        <p class="font-weight-bold" translate>Date</p>
                        <p>{{moment(step.date).format('DD/MM/YYYY')}}</p>
                    </div>
                    <div class="pl-3 pl-md-5">
                        <p class="font-weight-bold" translate>Description</p>
                        <p>{{step.description}}</p>
                    </div>
                    <div class="pl-3 pl-md-5">
                        <p class="font-weight-bold" translate>Documents</p>
                        <div *ngIf="subject?.flow[i]?.documents && subject?.flow[i]?.documents?.length">
                            <div class="row ml-0 mr-0">
                                <div *ngFor="let document of subject?.flow[i]?.documents; let d=index"
                                    class="col-12 col-md-4 col-lg-3 mr-2 pl-0">
                                    <p><a target="_blank" [href]="document?.url"><i
                                                class="fas fa-file-alt main-color fa-2x"></i></a></p>
                                    <p>{{document.name}}</p>
                                    <p>{{document.type | translate}}</p>
                                </div>
                            </div>
                        </div>
                        <p><a data-toggle="modal" data-target="#documentUploadModal" href="javascript:void(0)"
                                (click)="flowIndexUpload = i"><i class="fas fa-plus main-color fa-2x"></i></a>
                        </p>
                    </div>
                </div>
                <p *ngIf="!subject?.flow.length">/</p>
            </div>
        </div>
    </div>

    <div *ngIf="updating" class="container p-3 p-md-5">
        <form class="row shadow rounded pt-3 pb-3" [formGroup]="updateSubjectForm"
            (ngSubmit)="onSubmit(updateSubjectForm)">
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Client</p>
                <span *ngIf="user && user.type==='INDIVIDUAL'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp;
                    {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp;
                    {{user.jmbg}} &nbsp;&nbsp;</span>
                <span *ngIf="user && user.type==='COMPANY'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp;
                    {{user.companyName}} &nbsp;&nbsp; {{user.mb}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp;
                    {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; </span>
            </div>
            <div class="col-lg-4 border-right">
                <p class="font-weight-bold" translate>Subject number</p>
                <p>{{subject?.subjectNumber}}</p>
                <div class="form-group">
                    <label for="officialSubjectNumber" class="font-weight-bold" translate>Official subject
                        number</label>
                    <input formControlName="officialSubjectNumber" type="text" id="officialSubjectNumber"
                        class="form-control">
                </div>
                <div class="form-group">
                    <label for="shortDescription" class="font-weight-bold" translate>Short description</label>
                    <input formControlName="shortDescription" type="text" id="shortDescription" class="form-control">
                </div>
                <div class="form-group">
                    <label for="physicalLocation" class="font-weight-bold" translate>Physical Location</label>
                    <input formControlName="physicalLocation" type="text" id="physicalLocation" class="form-control">
                </div>

            </div>
            <div class="col-lg-4 border-right">
                <div class="form-group">
                    <label for="type" class="font-weight-bold" translate>Type of subject</label><span
                        class="text-danger">*</span>
                    <select class="form-control cursor-pointer" formControlName="type" id="type"
                        [ngClass]="{'is-invalid':updateSubjectForm.get('type').invalid && (updateSubjectForm.get('type').touched || updateSubjectForm.get('type').dirty)}">
                        <option *ngFor="let typeOption of subjectTypeOptions" [value]="typeOption.value">
                            {{typeOption.label | translate}}</option>
                    </select>
                    <div *ngIf="updateSubjectForm.get('type').invalid && (updateSubjectForm.get('type').touched || updateSubjectForm.get('type').dirty)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="dateOpened" class="font-weight-bold" translate>Date of subject opening</label><span
                        class="text-danger">*</span>
                    <input formControlName="dateOpened" type="text" id="dateOpened" class="form-control cursor-pointer"
                        [ngClass]="{'is-invalid':updateSubjectForm.get('dateOpened').invalid && (updateSubjectForm.get('dateOpened').touched || updateSubjectForm.get('dateOpened').dirty)}"
                        [bsConfig]="datePickerConfig" bsDatepicker>
                    <div *ngIf="updateSubjectForm.get('dateOpened').invalid && (updateSubjectForm.get('dateOpened').touched || updateSubjectForm.get('dateOpened').dirty)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="dateClosed" class="font-weight-bold" translate>Date of subject closing</label>
                    <input formControlName="dateClosed" type="text" id="dateClosed" class="form-control cursor-pointer"
                        [bsConfig]="datePickerConfig" bsDatepicker>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="stauts" class="font-weight-bold" translate>Subject status</label><span
                        class="text-danger">*</span>
                    <select class="form-control cursor-pointer" formControlName="status" id="status"
                        [ngClass]="{'is-invalid':updateSubjectForm.get('status').invalid && (updateSubjectForm.get('status').touched || updateSubjectForm.get('status').dirty)}">
                        <option *ngFor="let statusOption of subjectStatusOptions" [value]="statusOption.value">
                            {{statusOption.label | translate}}</option>
                    </select>
                    <div *ngIf="updateSubjectForm.get('status').invalid && (updateSubjectForm.get('status').touched || updateSubjectForm.get('status').dirty)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
            </div>
            <div class="col-12 border-top mt-3 pt-3">
                <div class="form-group">
                    <label for="flow" class="font-weight-bold" translate>Subject flow</label>
                    <div formArrayName="flow"
                        *ngFor="let step of updateSubjectForm.get('flow')['controls'];let i = index">
                        <div class="p-3" [formGroupName]="i">
                            <div class="w-100 d-flex mb-3 border-bottom font-weight-bold"><span
                                    translate>Step</span>&nbsp;{{i+1}}<i class="fas fa-times cursor-pointer ml-auto"
                                    (click)="removeStep(i)"></i></div>
                            <div class="form-group">
                                <label for="date" translate>Date</label><span class="text-danger">*</span>
                                <input formControlName="date" class="cursor-pointer form-control"
                                    [bsConfig]="datePickerConfig" bsDatepicker
                                    [ngClass]="{'is-invalid':updateSubjectForm.get('flow').get(i.toString()).get('date').invalid && (updateSubjectForm.get('flow').get(i.toString()).get('date').touched || updateSubjectForm.get('flow').get(i.toString()).get('date').dirty)}">
                                <div *ngIf="updateSubjectForm.get('flow').get(i.toString()).get('date').invalid && (updateSubjectForm.get('flow').get(i.toString()).get('date').touched || updateSubjectForm.get('flow').get(i.toString()).get('date').dirty)"
                                    class="invalid-feedback" translate>
                                    This field is required
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="description" translate>Description</label><span class="text-danger">*</span>
                                <textarea formControlName="description" rows="5" class="form-control"
                                    [ngClass]="{'is-invalid':updateSubjectForm.get('flow').get(i.toString()).get('description').invalid && (updateSubjectForm.get('flow').get(i.toString()).get('description').touched || updateSubjectForm.get('flow').get(i.toString()).get('description').dirty)}"></textarea>
                                <div *ngIf="updateSubjectForm.get('flow').get(i.toString()).get('description').invalid && (updateSubjectForm.get('flow').get(i.toString()).get('description').touched || updateSubjectForm.get('flow').get(i.toString()).get('description').dirty)"
                                    class="invalid-feedback" translate>
                                    This field is required
                                </div>
                            </div>
                            <div class="form-group">
                                <label translate>Documents</label>
                                <div *ngIf="subject?.flow[i]?.documents && subject?.flow[i]?.documents?.length">
                                    <div class="row ml-0 mr-0">
                                        <div *ngFor="let document of subject?.flow[i]?.documents; let d=index"
                                            class="col-12 col-md-4 col-lg-3 pl-0 mr-2">
                                            <p><a target="_blank" [href]="document?.url"><i
                                                        class="fas fa-file-alt main-color fa-2x"></i></a></p>
                                            <p>{{document.name}}</p>
                                            <p>{{document.type}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pl-3"><span class="cursor-pointer" (click)="addStep()"><i
                                class="fas fa-plus main-color"></i><span translate> Add Step</span></span></div>
                </div>
            </div>
            <div class="col-12 d-flex" *ngIf="errorMessage">
                <div class="mt-3 mb-3 ml-auto alert alert-danger" role="alert">
                    {{errorMessage}}
                </div>
            </div>
            <div class="col-12 text-right mt-3">
                <button type="submit" class="btn btn-primary" [disabled]="updateSubjectForm.invalid" translate>Save
                    changes</button>
            </div>
        </form>
    </div>


    <div class="container-fluid mt-3 mb-3">
        <div class="row border-bottom">
            <div class="col-12" translate>Bills</div>
        </div>
        <div class="col-12 text-right">
            <i class="fas fa-check fa-xs text-success"></i>&nbsp;<span class="font-size-note"
                translate>Paid</span>&nbsp;&nbsp;
            <i class="fas fa-exclamation-circle fa-xs text-danger"></i>&nbsp;<span class="font-size-note" translate>Not
                paid</span>
        </div>
    </div>
    <div class="container p-3 p-md-5">
        <div class="row">
            <div class="col-12 p-3 p-sm-0">
                <div class="card" *ngFor="let bill of bills; let i=index">
                    <div class="card-header p-3 cursor-pointer font-weight-bold d-flex flex-row align-items-center"
                        (click)="flipArrow(i)" [id]="'heading'+i" data-toggle="collapse"
                        [attr.data-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                        <i class="fas"
                            [ngClass]="{'fa-check fa-xs text-success': bill.paid, 'fa-exclamation-circle fa-xs text-danger':!bill.paid}"></i>&nbsp;<span
                            translate>Bill</span>&nbsp;{{bill.billNumber}}&nbsp;<i [id]="'arrow'+i"
                            class="fas fa-chevron-down ml-auto main-color"></i>
                    </div>
                    <div [id]="'collapse'+i" class="collapse" [attr.aria-labelledby]="'heading'+i">
                        <div class="card-body pt-0">
                            <div class="row justify-content-end pt-1"><a href="javascript:void(0)"><i
                                        class="fas fa-external-link-alt main-color" (click)="window.scroll(0,0)"
                                        [routerLink]="'/bill'" [queryParams]="{id:bill._id}"></i></a></div>
                            <div class="container p-4">
                                <div class="row shadow rounded pt-3 pb-3">
                                    <div class="col-md-6">
                                        <p class="font-weight-bold" translate>Bill number</p>
                                        <p>{{bill.billNumber}}</p>
                                        <p class="font-weight-bold" translate>Date of bill creation</p>
                                        <p>{{moment(bill.dateMade).format('DD/MM/YYYY')}}</p>
                                        <p class="font-weight-bold" translate>Date to be paid</p>
                                        <p>{{moment(bill.dateToBePaid).format('DD/MM/YYYY')}}</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="font-weight-bold" translate>Total amount</p>
                                        <p>{{ bill.price.toFixed(2) }} RSD</p>
                                        <p class="font-weight-bold" translate>Paid so far</p>
                                        <p>{{ bill.paidSoFar.toFixed(2) }} RSD</p>
                                        <p class="font-weight-bold" translate>Bill status</p>
                                        <p *ngIf="bill?.paid"><i class="fas fa-check text-success"></i>&nbsp;<span
                                                translate>Paid</span></p>
                                        <p *ngIf="!bill?.paid"><i
                                                class="fas fa-exclamation-circle text-danger"></i>&nbsp;<span
                                                translate>Not paid</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button class="btn btn-primary btn-block mt-5" [routerLink]="'/create-bill'"
                        [queryParams]="{id:subject?._id}" translate>Add Bill</button>
                </div>
            </div>
        </div>
    </div>
</jt-side-nav>

<div class="modal fade" id="documentUploadModal" tabindex="-1" role="dialog" aria-labelledby="documentUploadModalLabel"
    aria-hidden="true">
    <div *ngIf="uploadDocumentFormInitialized" class="modal-dialog" role="document">
        <form [formGroup]="uploadDocumentForm" (ngSubmit)="uploadDocument(uploadDocumentForm)" class="modal-content"
            enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="documentUploadModalLabel" translate>Upload Document</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="name" class="font-weight-bold" translate>Document name</label><span
                        class="text-danger">*</span>
                    <input formControlName="name" type="text" id="name" class="form-control"
                        [ngClass]="{'is-invalid':uploadDocumentForm.get('name').invalid && (uploadDocumentForm.get('name').touched || uploadDocumentForm.get('name').dirty)}">
                    <div *ngIf="uploadDocumentForm.get('name').invalid && (uploadDocumentForm.get('name').touched || uploadDocumentForm.get('name').dirty)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="type" class="font-weight-bold" translate>Document type</label><span
                        class="text-danger">*</span>
                    <select class="form-control cursor-pointer" formControlName="type" id="type"
                        [ngClass]="{'is-invalid':uploadDocumentForm.get('type').invalid && (uploadDocumentForm.get('type').touched || uploadDocumentForm.get('type').dirty)}">
                        <option *ngFor="let typeOption of documentTypeOptions" [value]="typeOption.value">
                            {{typeOption.label | translate}}</option>
                    </select>
                    <div *ngIf="uploadDocumentForm.get('type').invalid && (uploadDocumentForm.get('type').touched || uploadDocumentForm.get('type').dirty)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="font-weight-bold" translate>Document file</label><span
                        class="text-danger">*</span>
                    <input formControlName="file" type="file" id="file" (change)="onFileChange($event)" class="d-block"
                        [ngClass]="{'is-invalid':uploadDocumentForm.get('file').invalid && (uploadDocumentForm.get('file').touched || uploadDocumentForm.get('file').dirty)}">
                    <div *ngIf="uploadDocumentForm.get('file').invalid && (uploadDocumentForm.get('file').touched || uploadDocumentForm.get('file').dirty)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" translate>Cancel</button>
                <button [disabled]="uploading" type="submit" class="btn btn-primary" translate>{{uploading ?
                    "Uploading...":"Upload"}}</button>
            </div>
        </form>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" translate>Archive subject</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p translate>Are you sure you want to archive subject <span>{{subject?.subjectNumber}}</span> ?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" translate>No</button>
                <button type="button" class="btn btn-primary" (click)="archiveSubject()" translate>Yes</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="retrieveModal" tabindex="-1" role="dialog" aria-labelledby="retrieveModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="retrieveModalLabel" translate>Retrieve subject from archive</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p translate>Are you sure you want to retrieve subject <span>{{subject?.subjectNumber}}</span> <span
                        translate> from archive</span>?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" translate>No</button>
                <button type="button" class="btn btn-primary" (click)="retrieveSubject()" translate>Yes</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loading" class="loading-bg">
    <mat-spinner [diameter]="75" class="m-auto"></mat-spinner>
</div>