import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

export enum NotificationType {
  DEFAULT = 'default',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

@Injectable()
export class NotificationService {

  constructor(
    private notifier: NotifierService,
    private translate: TranslateService
  ) { }

  create(type: NotificationType, message: string) {
    this.notifier.notify(type, message);
  }

  clearAllNotification() {
    this.notifier.hideAll();
  }

  public throwServerError() {
    this.create(NotificationType.ERROR, this.translate.instant('Server error'));
  }
}