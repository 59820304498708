import { Service } from "./service";

export interface Services {
    service: Service,
    price: number,
    discount: number,
    description: string
}

export interface Payments {
    date: Date,
    amount: number
}

export interface Installments {
    date: Date,
    price: number
}

export enum PaymentMethod {
    CASH = "CASH",
    CARD = "CARD"
}

export interface Bill {
    _id: string,
    billNumber: string,
    dateMade: Date,
    dateToBePaid: Date,
    paid: boolean,
    price: number,
    discount: number,
    paidSoFar: number,
    subjectId: string,
    services:Services[],
    payments: Payments[],
    installments: Installments[],
    paymentMethod: PaymentMethod,
    billPDF: string,
    paymentOrderPDF: string
}