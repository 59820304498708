<jt-side-nav>
    <jt-page-title [title]="'Archive'"></jt-page-title>
    <div class="container margin-top-52 p-3 p-md-5">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header p-3 cursor-pointer font-weight-bold d-flex flex-row align-items-center" id="headingSearch" data-toggle="collapse" data-target="#collapseSearch" aria-expanded="false" aria-controls="collapseSearch" translate>
                        <i class="fas fa-search main-color"></i>&nbsp;Search filter&nbsp;<i class="fas fa-chevron-down ml-auto main-color"></i>
                    </div>
                    <div id="collapseSearch" class="collapse" aria-labelledby="headingSearch">
                      <div class="card-body pt-0">
                          <div class="row justify-content-end pt-1"><a href="javascript:void(0)" (click)="cancelFilter()" class="main-color main-color-hover font-size-note" translate><i class="fas fa-times main-color"></i>&nbsp;&nbsp;cancel filter</a></div>
                          <div class="container p-3">
                              <div class="row">
                                  <div class="col-12">
                                    <div class="form-group">
                                      <p class="font-weight-bold" translate>Type of subject</p>
                                      <i class="fas fa-search"></i>
                                      <select class="form-control cursor-pointer search" id="type" (change)="onTypeChange($event)">
                                          <option *ngFor="let type of subjectTypeOptions" [value]="type.value">{{type.label | translate}}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group">
                                        <p class="font-weight-bold" translate>Search subject</p>
                                        <i class="fas fa-search"></i>
                                        <input class="form-control search" [placeholder]="searchPlaceHolder | translate" type="text" id="filterWord" [(ngModel)]="filterWord" (input)="onInput()">
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
        <div class="row mt-5 mb-5">
            <div class="col-12 overflow-x">
                <table *ngIf="!empty" class="table table-hover shadow rounded">
                    <thead>
                        <tr>
                          <th scope="col"><span translate>Subject</span> #</th>
                          <th scope="col" translate>Official subject number</th>
                          <th scope="col" translate>Short description</th>
                          <th scope="col" translate>Type of subject</th>
                          <th scope="col" translate>Date of subject opening</th>
                          <th scope="col" translate>Subject status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let subject of subjects; let i=index" class="cursor-pointer" [routerLink]="'/subject'" [queryParams]="{id:subject._id}">
                          <th scope="row">{{subject.subjectNumber}}</th>
                          <td>{{subject.officialSubjectNumber ? subject.officialSubjectNumber:''}}</td>
                          <td>{{subject.shortDescription ? subject.shortDescription:''}}</td>
                          <td>{{subject.type | translate}}</td>
                          <td>{{moment(subject.dateOpened).format('DD/MM/YYYY')}}</td>
                          <td>{{subject.status | translate}}</td>
                        </tr>
                      </tbody>
                </table>
            </div>
            <div *ngIf="empty" class="col-12">
                <div class="alert alert-danger" role="alert" translate>Unfortunatelly there are no matching subjects</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <pagination [totalItems]="totalItems" 
                [itemsPerPage]="itemsParPage"
                [(ngModel)]="currentPage" 
                [boundaryLinks]="true"
                previousText="&lsaquo;" 
                nextText="&rsaquo;" 
                firstText="&laquo;" 
                lastText="&raquo;"
                (pageChanged)="onPageChange($event)"
                class="ml-auto mr-auto d-flex">
                </pagination>
            </div>
        </div>
    </div>
</jt-side-nav>

