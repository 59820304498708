import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { SelectOption } from '../shared/entities/select-option';
import { Type, User } from '../shared/entities/user';
declare var $:any

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  
  clientType = `INDIVIDUAL`
  filterWord = ``
  searchPlaceHolder = `type client number, name, JMBG, company name, MB, email...`
  users: Array<User> = []
  currentPage = 1
  itemsParPage = 10
  totalItems = 0
  empty = false
  clientTypeOptions: SelectOption[] = []
  payload = {
    limit: 10,
    page: 1
  }

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getClients()
    this.initClientTypeOptions()
    $('.card-header').click(function(){
      $(this).find(".fa-chevron-down").toggleClass("arrow-flip");
    })
  }

  onTypeChange(event:Event) {
    this.clientType = (<HTMLSelectElement>event.target).value
    this.currentPage = 1
    this.getClients()
  }

  onInputChange() {
    this.currentPage = 1
    this.getClients()
  }

  onPageChange(event:any) {
    this.currentPage = event.page
    this.getClients()
  }

  cancelFilter() {
    this.clientType = "INDIVIDUAL";
    (<HTMLSelectElement>document.getElementById('type')).value = this.clientType
    this.filterWord = ``
    this.getClients()
  }

  private initClientTypeOptions() {
    this.clientTypeOptions = [
      {value: Type.INDIVIDUAL, label: this.translate.instant(Type.INDIVIDUAL)},
      {value: Type.COMPANY, label: this.translate.instant(Type.COMPANY)}
    ]
  }

  private getClients() {
    this.empty = false
    this.payload.limit = this.itemsParPage
    this.payload.page = this.currentPage
    this.payload['type']= this.clientType
    delete this.payload['filterWord']
    if(this.filterWord) {
      this.payload['filterWord'] = this.filterWord
    }
    this.auth.filterClients(this.payload).subscribe(response=>{
      if(!response.users.length){
        this.empty = true
      }
      this.users = response.users
      this.totalItems = response.totalItems
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })
  }

}
