import { Component, OnInit, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout'
import { Observable } from 'rxjs'
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
declare var $:any;

@Component({
  selector: 'jt-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  isHandset: Observable<BreakpointState> = this.breakpointobserver.observe('(max-width: 991px)')
  window = window

  constructor(
    private breakpointobserver: BreakpointObserver,
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService) { }

  ngOnInit(): void {
    $('.sidenav-menu-toogle').click(function(){
      $(this).next('.sidenav-menu-list').toggle(500)
      $(this).find("mat-icon").toggleClass("arrow-flip");
    })

    this.translate.use(this.translate.currentLang)
    if(this.auth.currentUser.language) {
      this.translate.use(this.auth.currentUser.language)
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent)=>{
      this.translate.use(event.lang)
    })
  }


  userLogout() {
    this.auth.logout();
  }

}
