import { Injectable } from '@angular/core'
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
  } from '@angular/common/http'
import { catchError } from 'rxjs/operators'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Observable, ObservableInput, throwError } from 'rxjs'
import { NotificationService, NotificationType } from '../noticifation/notification.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private notification: NotificationService,
        private translate: TranslateService,
    ){}

    public intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
        return next.handle(req).pipe(
            catchError(
                (error: HttpErrorResponse): ObservableInput<HttpEvent<Object>> => {
                    let errorMessage = ''
                    if(error.error instanceof ErrorEvent) {
                        errorMessage = error.error.message
                        return throwError(error)
                    }
                    errorMessage = error.message
                    return throwError(error)
                }
            )
        )
    }
}