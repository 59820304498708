import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { SelectOption } from '../shared/entities/select-option';
import { Status, Type } from '../shared/entities/subject';
import { User } from '../shared/entities/user';

@Component({
  selector: 'app-create-subject',
  templateUrl: './create-subject.component.html',
  styleUrls: ['./create-subject.component.css']
})
export class CreateSubjectComponent implements OnInit {

  createSubjectForm: FormGroup
  user: User
  users: Array<User>
  errorMessage = ''
  clientSearch = false
  searchClientText = `type client number, name, JMBG, company name, MB, email...`
  searchFail = false
  clientIdTouched = false
  subjectTypeOptions: SelectOption[] = []
  subjectStatusOptions: SelectOption[] = []
  datePickerConfig = {
    dateInputFormat: 'DD/MM/YYYY',
    containerClass: 'theme-dark-blue'
  }
  loading = false
  today = new Date()
  flow: FormArray
  newStep(): FormGroup {
    return this.fb.group({
      date: [null, Validators.required],
      description: [null, Validators.required]
    })
  }

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private notification: NotificationService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.initSubjectTypeOptions()
    this.initSubjectStatusOptions()
    this.route.queryParams.subscribe(params => {
      let id = params['id']
      if (id) {
        this.auth.getClient(id).subscribe(response => {
          if (response) {
            this.user = response.user
            this.createSubjectForm.controls['clientId'].setValue(response.user._id)
          }
        }, err => { return })
      }
      return
    })
    this.initForm()
  }

  addUser(setUser: User) {
    this.user = setUser
    this.createSubjectForm.controls['clientId'].setValue(setUser._id)
  }

  onUserSearch(event: Event) {
    this.searchFail = false
    let search = null
    if ((<HTMLInputElement>event.target).value) {
      search = (<HTMLInputElement>event.target).value
    }
    this.auth.searchAllClients(search).subscribe(response => {
      this.users = response
      if (!this.users.length) {
        this.searchFail = true
      }
    }, err => {
      this.notification.throwServerError()
    })
  }

  private initForm() {
    this.createSubjectForm = this.fb.group({
      clientId: [null, Validators.required],
      officialSubjectNumber: [null],
      shortDescription: [null],
      physicalLocation: [null],
      dateOpened: [this.today, Validators.required],
      dateClosed: [null],
      status: [Status.ACTIVE, Validators.required],
      flow: this.fb.array([]),
      type: [null, Validators.required]
    })
  }

  addStep(): void {
    this.flow = this.createSubjectForm.get('flow') as FormArray
    this.flow.push(this.newStep())
  }

  removeStep(index): void {
    this.flow = this.createSubjectForm.get('flow') as FormArray
    this.flow.removeAt(index)
  }

  onSubmit(form: FormGroup) {
    if (form.invalid) {
      return
    }
    let payload;
    payload = {
      clientId: form.get('clientId').value,
      type: form.get('type').value,
      status: form.get('status').value,
      dateOpened: form.get('dateOpened').value
    }
    if (form.get('officialSubjectNumber').value) {
      payload.officialSubjectNumber = form.get('officialSubjectNumber').value
    }
    if (form.get('shortDescription').value) {
      payload.shortDescription = form.get('shortDescription').value
    }
    if (form.get('dateClosed').value) {
      payload.dateClosed = form.get('dateClosed').value
    }
    if (form.get("physicalLocation").value) {
      payload.physicalLocation = form.get("physicalLocation").value
    }
    this.flow = form.get('flow') as FormArray
    if (this.flow.length) {
      payload.flow = form.get('flow').value
    }

    this.loading = true;

    this.auth.createSubject(payload).subscribe(response => {
      this.loading = false;
      this.notification.create(NotificationType.SUCCESS, this.translate.instant('Subject created successfully'))
      this.router.navigate(['/subject'], { queryParams: { id: response._id } })
    }, err => {
      this.loading = false
      if (err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.errorMessage = this.translate.instant(err.error.message)
    })

  }

  private initSubjectTypeOptions() {
    this.subjectTypeOptions = [
      { value: Type.IZVRSNIPOSTUPAK, label: this.translate.instant(Type.IZVRSNIPOSTUPAK) },
      { value: Type.KRIVICNIPOSTUPAK, label: this.translate.instant(Type.KRIVICNIPOSTUPAK) },
      { value: Type.PARNICNIPOSTUPAK, label: this.translate.instant(Type.PARNICNIPOSTUPAK) },
      { value: Type.PORODICNIODNOSI, label: this.translate.instant(Type.PORODICNIODNOSI) },
      { value: Type.PREKRSAJI, label: this.translate.instant(Type.PREKRSAJI) },
      { value: Type.PRIVREDNISPOR, label: this.translate.instant(Type.PRIVREDNISPOR) },
      { value: Type.RADNIODNOSI, label: this.translate.instant(Type.RADNIODNOSI) },
      { value: Type.UGOVORI, label: this.translate.instant(Type.UGOVORI) },
      { value: Type.UPRAVNIPOSTUPAK, label: this.translate.instant(Type.UPRAVNIPOSTUPAK) },
      { value: Type.UPRAVNISPOR, label: this.translate.instant(Type.UPRAVNISPOR) },
      { value: Type.USTAVNAZALBA, label: this.translate.instant(Type.USTAVNAZALBA) },
      { value: Type.DISCIPLINSKIPOSTUPAK, label: this.translate.instant(Type.DISCIPLINSKIPOSTUPAK) },
      { value: Type.RAZVODBRAKA, label: this.translate.instant(Type.RAZVODBRAKA) },
      { value: Type.OSTAVINSKIPOSTUPAK, label: this.translate.instant(Type.OSTAVINSKIPOSTUPAK) }
    ]
  }

  private initSubjectStatusOptions() {
    this.subjectStatusOptions = [
      { value: Status.ACTIVE, label: this.translate.instant(Status.ACTIVE) },
      { value: Status.PENDING, label: this.translate.instant(Status.PENDING) },
      { value: Status.CLOSED, label: this.translate.instant(Status.CLOSED) }
    ]
  }

}
