<jt-side-nav>
    <jt-page-title [title]="'Add Bill'"></jt-page-title>
    <div class="container margin-top-52 mb-3 p-3 p-md-5">
        <div class="row">
            <div class="col-12">
                <form class="w-100" [formGroup]="createBillForm" (ngSubmit)="onSubmit(createBillForm)">
                    <div class="form-group">
                        <label translate>Client</label>
                        <p>
                            <span *ngIf="user && user.type==='INDIVIDUAL'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp; {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp; {{user.jmbg}} &nbsp;&nbsp;</span>
                            <span *ngIf="user && user.type==='COMPANY'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp; {{user.companyName}} &nbsp;&nbsp; {{user.mb}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp; {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; </span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label translate>Subject</label>
                        <p>
                            <span *ngIf="subject"> &nbsp;&nbsp;#{{ subject.subjectNumber }} &nbsp;&nbsp; {{ subject.officialSubjectNumber ? subject.officialSubjectNumber:"" }} &nbsp;&nbsp; {{ subject.shortDescription ? subject.shortDescription:"" }} &nbsp;&nbsp; {{ subject.type | translate}} &nbsp;&nbsp; {{ moment(subject.dateOpened).format("DD/MM/YYYYY") }} &nbsp;&nbsp; {{ subject.status | translate}} &nbsp;&nbsp;</span>
                        </p>
                    </div>
                    <div class="form-group">
                        <label for="dateMade" translate>Date of bill creation</label><span class="text-danger">*</span>
                        <input formControlName="dateMade" type="text" id="dateMade" class="form-control cursor-pointer" [ngClass]="{'is-invalid':createBillForm.get('dateMade').invalid && (createBillForm.get('dateMade').touched || createBillForm.get('dateMade').dirty)}" [bsConfig]="datePickerConfig" [bsValue]="today" bsDatepicker>
                        <div *ngIf="createBillForm.get('dateMade').invalid && (createBillForm.get('dateMade').touched || createBillForm.get('dateMade').dirty)" class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="dateToBePaid" translate>Date to be paid</label><span class="text-danger">*</span>
                        <input formControlName="dateToBePaid" type="text" id="dateToBePaid" class="form-control cursor-pointer" [ngClass]="{'is-invalid':createBillForm.get('dateToBePaid').invalid && (createBillForm.get('dateToBePaid').touched || createBillForm.get('dateToBePaid').dirty)}" [bsConfig]="datePickerConfig" bsDatepicker>
                        <div *ngIf="createBillForm.get('dateToBePaid').invalid && (createBillForm.get('dateToBePaid').touched || createBillForm.get('dateToBePaid').dirty)" class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="services" translate>Services</label><span class="text-danger">*</span>
                        <div class="overflow-x">
                            <table *ngIf="createBillForm.get('services')['controls'].length" class="table shadow rounded" >
                                <thead>
                                    <tr>
                                        <th scope="col" translate>Service title</th>
                                        <th scope="col" translate>Discount</th>
                                        <th scope="col" translate>Price(RSD)</th>
                                        <th scope="col" translate>Description</th>
                                        <th scope="col" translate>Remove</th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="services" *ngFor="let service of createBillForm.get('services')['controls']; let i=index">
                                    <tr [formGroupName]="i">
                                        <td>{{createBillForm.get('services').get(i.toString()).get('service').value.title}}</td>
                                        <td><select class="form-control cursor-pointer" (change)="onServiceDiscountChange(i)" formControlName="discount" id="discount"><option *ngFor="let dis of discount" [value]="dis.value">{{dis.label}}</option></select></td>
                                        <td><input class="form-control" formControlName="price" type="number" id="price" [ngClass]="{'is-invalid':createBillForm.get('services').get(i.toString()).get('price').invalid && (createBillForm.get('services').get(i.toString()).get('price').touched || createBillForm.get('services').get(i.toString()).get('price').dirty)}"></td>
                                        <td class="cursor-pointer" [id]="'heading'+i" data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="true" [attr.aria-controls]="'collapse'+i" (click)="flipArrow(i)"><span translate>Description</span>&nbsp;<i [id]="'down'+i" class="fas fa-chevron-down main-color"></i></td>
                                        <td class="text-center"><i (click)="removeService(i)" class="fas fa-times main-color cursor-pointer"></i></td>
                                    </tr>
                                    <tr [formGroupName]="i" class="bg-desc">
                                        <td colspan="5" class="p-0">
                                            <div class="pr-5 pl-5 mt-3 mb-3 collapse" [id]="'collapse'+i" [attr.aria-labelledby]="'heading'+i"><textarea class="form-control" formControlName="description"></textarea></div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th scope="col" translate>Total Discount</th>
                                        <th scope="col" colspan="3" translate>Total amount</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th scope="col"><select class="form-control cursor-pointer" formControlName="discount" id="discount"><option *ngFor="let dis of discount" [value]="dis.value">{{dis.label}}</option></select></th>
                                        <th scope="col" colspan="3">{{ totalAmount.toFixed(2) }} RSD</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div [ngClass]="{'is-invalid': servicesTouched && (!services || !services.length)}">
                            <span class="cursor-pointer" data-toggle="modal" data-target=".bd-example-modal-lg"><i class="fas fa-plus main-color"></i><span translate> Add Service</span></span>
                        </div>
                        <div *ngIf="servicesTouched && (!services || !services.length)" class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="installments" translate>Installments</label>
                        <select class="form-control cursor-pointer" (change)="onInstallmentsChange($event)">
                            <option *ngFor="let ins of installmentsSelect" [value]="ins.value">{{ins.label}}</option>
                        </select>
                        <div *ngIf="installmentsNumber > 0 && installments.length" class="row">
                            <div formArrayName="installments" *ngFor="let install of createBillForm.get('installments')['controls']; let i=index" [ngClass]="{'col-lg-4':installments.length===3, 'col-lg-6':installments.length===2}">
                                <div class="form-group mt-3" [formGroupName]="i">
                                    <label for="price" translate>Price(RSD)</label>
                                    <p>{{ createBillForm.get('installments').get(i.toString()).get('price').value }} RSD</p>
                                    <label for="date" translate>Date of pay</label><span class="text-danger">*</span>
                                    <input formControlName="date" class="form-control cursor-pointer" [bsConfig]="datePickerConfig" bsDatepicker [ngClass]="{'is-invalid':createBillForm.get('installments').get(i.toString()).get('date').invalid && (createBillForm.get('installments').get(i.toString()).get('date').touched || createBillForm.get('installments').get(i.toString()).get('date').dirty)}">
                                    <div *ngIf="createBillForm.get('installments').get(i.toString()).get('date').invalid && (createBillForm.get('installments').get(i.toString()).get('date').touched || createBillForm.get('installments').get(i.toString()).get('date').dirty)" class="invalid-feedback" translate>
                                        This field is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="paymentMethod" translate>Payment method</label>
                        <select class="form-control cursor-pointer" formControlName="paymentMethod" id="paymentMethod" class="form-control cursor-pointer" [ngClass]="{'is-invalid':createBillForm.get('paymentMethod').invalid && (createBillForm.get('paymentMethod').touched || createBillForm.get('paymentMethod').dirty)}">
                            <option *ngFor="let method of paymenyMethodSelect" [value]="method.value">{{method.label}}</option>
                        </select>
                        <div *ngIf="createBillForm.get('paymentMethod').invalid && (createBillForm.get('paymentMethod').touched || createBillForm.get('paymentMethod').dirty)" class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="paidSoFar" translate>Paid so far</label>
                        <p>{{(paidSoFar).toFixed(2)}} RSD</p>
                    </div>
                    <div class="form-group">
                        <label for="payments" translate>Payments</label>
                        <div *ngIf="payments && payments.length" class="row" formArrayName="payments">
                            <div *ngFor="let payment of createBillForm.get('payments')['controls']; let i=index" [formGroupName]="i" class="col-lg-4">
                                <div class="mb-3">
                                    <span class="d-block w-100 text-right"><i class="fas fa-times main-color cursor-pointer" (click)="removePayment(i)"></i></span>
                                    <label translate for="date">Date paid</label><span class="text-danger">*</span>
                                    <input formControlName="date" class="form-control cursor-pointer" [bsConfig]="datePickerConfig" bsDatepicker [ngClass]="{'is-invalid':createBillForm.get('payments').get(i.toString()).get('date').invalid && (createBillForm.get('payments').get(i.toString()).get('date').touched || createBillForm.get('payments').get(i.toString()).get('date').dirty)}">
                                    <div *ngIf="createBillForm.get('payments').get(i.toString()).get('date').invalid && (createBillForm.get('payments').get(i.toString()).get('date').touched || createBillForm.get('payments').get(i.toString()).get('date').dirty)" class="invalid-feedback" translate>
                                        This field is required
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label translate for="amount">Amount(RSD)</label><span class="text-danger">*</span>
                                    <input formControlName="amount" class="form-control" [ngClass]="{'is-invalid':createBillForm.get('payments').get(i.toString()).get('amount').invalid && (createBillForm.get('payments').get(i.toString()).get('amount').touched || createBillForm.get('payments').get(i.toString()).get('amount').dirty)}">
                                    <div *ngIf="createBillForm.get('payments').get(i.toString()).get('amount').invalid && (createBillForm.get('payments').get(i.toString()).get('amount').touched || createBillForm.get('payments').get(i.toString()).get('amount').dirty)" class="invalid-feedback" translate>
                                        This field is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span (click)="addPayment()" class="cursor-pointer"><i class="fas fa-plus main-color"></i><span translate> Add Payment</span></span>
                        </div>
                    </div>
                    <div class="mt-3 mb-3 alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
                    <button class="btn btn-primary mt-3" type="submit" [disabled]="createBillForm.invalid || (!services || !services.length)" translate>Add Bill</button>
                </form>
            </div>
        </div>
    </div>
</jt-side-nav>

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel" translate>Available services</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="servicesTouched = true">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body overflow-auto p-3">
                <table class="table table-hover" *ngIf="!availableEmpty">
                    <thead>
                        <tr>
                            <th scope="col" translate>Service title</th>
                            <th scope="col" translate>Service category</th>
                            <th scope="col" translate>Service price(RSD)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let serve of availableServices" class="cursor-pointer" (click)="addService(serve)" aria-hidden="true" data-dismiss="modal" aria-label="Close">
                            <td>{{ serve.title }}</td>
                            <td>{{ serve.category | translate}}</td>
                            <td>{{ serve.price ? serve.price.toFixed(2)+" RSD":"0 RSD" }}</td>
                        </tr>
                    </tbody>
                </table>
              <div *ngIf="availableEmpty" class="alert alert-danger" role="alert" translate>No available services for this bill</div>
            </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="loading-bg">
    <mat-spinner [diameter]="75" class="m-auto"></mat-spinner>
  </div>
