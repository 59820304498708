<jt-side-nav>
    <jt-page-title [title]="'Services'"></jt-page-title>
    <div class="container margin-top-52 p-3 p-md-5">
        <div class="row mb-5">
            <div class="col-12">
                <button class="btn btn-primary" data-toggle="modal" (click)="initCreateServiceForm()" data-target="#createService" translate>Add Service</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header p-3 cursor-pointer font-weight-bold d-flex flex-row align-items-center" id="headingSearch" data-toggle="collapse" data-target="#collapseSearch" aria-expanded="false" aria-controls="collapseSearch" translate>
                        <i class="fas fa-search main-color"></i>&nbsp;Search filter&nbsp;<i class="fas fa-chevron-down ml-auto main-color"></i>
                    </div>
                    <div id="collapseSearch" class="collapse" aria-labelledby="headingSearch">
                      <div class="card-body pt-0">
                          <div class="row justify-content-end pt-1"><a href="javascript:void(0)" (click)="cancelFilter()" class="main-color main-color-hover font-size-note" translate><i class="fas fa-times main-color"></i>&nbsp;&nbsp;cancel filter</a></div>
                          <div class="container p-3">
                              <div class="row">
                                  <div class="col-12">
                                    <div class="form-group">
                                      <p class="font-weight-bold" translate>Service category</p>
                                      <i class="fas fa-search"></i>
                                      <select class="form-control cursor-pointer search" id="type" (change)="onTypeChange($event)">
                                          <option *ngFor="let type of categories" [value]="type.value">{{type.label | translate}}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group">
                                        <p class="font-weight-bold" translate>Search service title</p>
                                        <i class="fas fa-search"></i>
                                        <input class="form-control search" [placeholder]="searchPlaceHolder | translate" type="text" id="filterWord" [(ngModel)]="filterWord" (input)="onInput()">
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
        <div class="row mt-5 mb-5">
            <div class="col-12 overflow-x">
                <table *ngIf="!empty" class="table table-hover shadow rounded">
                    <thead>
                        <tr>
                          <th scope="col"translate>Service title</th>
                          <th scope="col" translate>Service category</th>
                          <th scope="col" translate>Service price(RSD)</th>
                          <th class="text-center" scope="col" translate>Update</th>
                          <th class="text-center" scope="col" translate>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let service of services; let i=index">
                          <td>{{service.title}}</td>
                          <td>{{service.category | translate}}</td>
                          <td>{{service.price ? service.price.toFixed(2):"/"}}</td>
                          <td class="text-center"><i (click)="initUpdateServiceForm(service, i)" data-toggle="modal" data-target="#updateService" class="fas fa-pencil-alt cursor-pointer main-color"></i></td>
                          <td class="text-center"><i (click)="serviceToDelete=service;index=i" data-toggle="modal" data-target="#deleteService" class="fas fa-trash-alt cursor-pointer main-color"></i></td>
                        </tr>
                      </tbody>
                </table>
            </div>
            <div *ngIf="empty" class="col-12">
                <div class="alert alert-danger" role="alert" translate>Unfortunatelly there are no matching services</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <pagination [totalItems]="totalItems" 
                [itemsPerPage]="itemsParPage"
                [(ngModel)]="currentPage" 
                [boundaryLinks]="true"
                previousText="&lsaquo;" 
                nextText="&rsaquo;" 
                firstText="&laquo;" 
                lastText="&raquo;"
                (pageChanged)="onPageChange($event)"
                class="ml-auto mr-auto d-flex">
                </pagination>
            </div>
        </div>
    </div>
</jt-side-nav>

<div *ngIf="adding" class="modal fade bd-example-modal-lg" id="createService" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel" translate>Add Service</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body overflow-auto p-3">
              <form [formGroup]="createServiceForm" (ngSubmit)="onCreateSubmit(createServiceForm)">
                  <div class="form-group">
                      <label for="title" translate>Service title</label><span class="text-danger">*</span>
                      <input id="title" type="text" class="form-control" formControlName="title" [ngClass]="{'is-invalid':createServiceForm.get('title').invalid && (createServiceForm.get('title').touched || createServiceForm.get('title').dirty)}">
                      <div *ngIf="createServiceForm.get('title').invalid && (createServiceForm.get('title').touched || createServiceForm.get('title').dirty)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="category" translate>Service category</label><span class="text-danger">*</span>
                    <select id="category" class="form-control cursor-pointer" formControlName="category" [ngClass]="{'is-invalid':createServiceForm.get('category').invalid && (createServiceForm.get('category').touched || createServiceForm.get('category').dirty)}">
                        <option *ngFor="let category of formCategories" [value]="category.value">{{category.label | translate}}</option>
                    </select>
                    <div *ngIf="createServiceForm.get('category').invalid && (createServiceForm.get('category').touched || createServiceForm.get('category').dirty)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="price" translate>Service price</label>
                    <input id="price" type="number" class="form-control" formControlName="price" [ngClass]="{'is-invalid':createServiceForm.get('price').invalid && (createServiceForm.get('price').touched || createServiceForm.get('price').dirty)}">
                    <div *ngIf="createServiceForm.get('price').invalid && (createServiceForm.get('price').touched || createServiceForm.get('price').dirty)" class="invalid-feedback" translate>
                      This field is required
                  </div>
                </div>
                <div *ngIf="addingErrorMessage" class="alert alert-danger mt-3 mb-3" role="alert">{{addingErrorMessage}}</div>
                <button class="btn btn-primary" type="submit" [disabled]="createServiceForm.invalid" translate>Add Service</button>
              </form>
            </div>
      </div>
    </div>
  </div>

  <div *ngIf="updating" class="modal fade bd-example-modal-lg" id="updateService" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel" translate>Update Service</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body overflow-auto p-3">
              <form [formGroup]="updateServiceForm" (ngSubmit)="onUpdateSubmit(updateServiceForm)">
                  <div class="form-group">
                      <label for="title" translate>Service title</label><span class="text-danger">*</span>
                      <input id="title" type="text" class="form-control" formControlName="title" [ngClass]="{'is-invalid':updateServiceForm.get('title').invalid && (updateServiceForm.get('title').touched || updateServiceForm.get('title').dirty)}">
                      <div *ngIf="updateServiceForm.get('title').invalid && (updateServiceForm.get('title').touched || updateServiceForm.get('title').dirty)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="category" translate>Service category</label><span class="text-danger">*</span>
                    <select id="category" class="form-control cursor-pointer" formControlName="category" [ngClass]="{'is-invalid':updateServiceForm.get('category').invalid && (updateServiceForm.get('category').touched || updateServiceForm.get('category').dirty)}">
                        <option *ngFor="let category of formCategories" [value]="category.value">{{category.label | translate}}</option>
                    </select>
                    <div *ngIf="updateServiceForm.get('category').invalid && (updateServiceForm.get('category').touched || updateServiceForm.get('category').dirty)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="price" translate>Service price</label>
                    <input id="price" type="number" class="form-control" formControlName="price" [ngClass]="{'is-invalid':updateServiceForm.get('price').invalid && (updateServiceForm.get('price').touched || updateServiceForm.get('price').dirty)}">
                    <div *ngIf="updateServiceForm.get('price').invalid && (updateServiceForm.get('price').touched || updateServiceForm.get('price').dirty)" class="invalid-feedback" translate>
                      This field is required
                  </div>
                </div>
                <div *ngIf="updatingErrorMessage" class="alert alert-danger mt-3 mb-3" role="alert">{{updatingErrorMessage}}</div>
                <button class="btn btn-primary" type="submit" [disabled]="updateServiceForm.invalid" translate>Save changes</button>
              </form>
            </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="deleteService" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel" translate>Delete service</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p><span translate>Are you sure you want to delete service</span> {{serviceToDelete?.title}} <span translate>from category</span> {{serviceToDelete?.category | translate}}?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" translate>No</button>
          <button type="button" class="btn btn-primary" (click)="deleteService()" translate>Yes</button>
        </div>
      </div>
    </div>
  </div>
