<div class="container-fluid" id="login-wrapper">
    <div class="row" id="login-top">
        <div class="col-4">
        </div>
        <div class="col-4 text-center mt-auto mb-auto" translate>
            Login
        </div>
        <div class="col-4 text-right">
            <a href="javascript:void(0)" class="mt-auto mb-auto" *ngIf="currentLanguage === 'en'" (click)="useLanguage('rs')"><span class="flag-icon flag-icon-rs flag-translate-icons"></span></a>
            <a href="javascript:void(0)" class="mt-auto mb-auto" *ngIf="currentLanguage === 'rs'" (click)="useLanguage('en')"><span class="flag-icon flag-icon-gb flag-translate-icons"></span></a>
        </div>
    </div>
    <img class="login-logo img-fluid" src="assets/justify-logo.png">
    <div *ngIf="errorMessage" class="text-center ml-auto mr-auto">
        <div class="mb-3 alert alert-danger" role="alert">{{errorMessage}}</div>
    </div>
    <form [formGroup]="LoginForm" (ngSubmit)="onSubmit(LoginForm)" class="mr-auto ml-auto mb-3 mt-3">
        <div class="form-group">
            <label for="email" translate>Email address</label>
            <input formControlName="email" (focus)="onFocus('email')" (blur)="onBlur('email')" [ngClass]="{'is-invalid': LoginForm.get('email').invalid && (((LoginForm.get('email').dirty || LoginForm.get('email').touched) && !emailFocused) || submitted)}" type="email" class="form-control" id="email">
            <div *ngIf="LoginForm.get('email').invalid && (LoginForm.get('email').dirty || LoginForm.get('email').touched || submitted)" class="invalid-feedback">
                <div *ngIf="LoginForm.get('email').errors.required" translate>This field is required</div>
                <div *ngIf="LoginForm.get('email').value && LoginForm.get('email').errors.email && (!emailFocused || submitted)" translate>Invalid Email</div>
            </div>
        </div>

        <div class="form-group">
            <label for="password" translate>Password</label>
            <input formControlName="password" (focus)="onFocus('password')" (blur)="onBlur('password')" [ngClass]="{'is-invalid': LoginForm.get('password').invalid && (((LoginForm.get('password').dirty || LoginForm.get('password').touched) && !passwordFocused) || submitted)}" type="password" class="form-control" id="password">
            <div *ngIf="LoginForm.get('password').invalid && (LoginForm.get('password').dirty || LoginForm.get('password').touched || submitted)" class="invalid-feedback">
                <div *ngIf="LoginForm.get('password').errors.required" translate>This field is required</div>
                <div *ngIf="LoginForm.get('password').errors.minlength && (!passwordFocused || submitted)" translate>Must have at least 8 characters</div>
            </div>
        </div>

        <button class="btn btn-primary btn-block mt-5" [disabled]="LoginForm.invalid" type="submit" translate>Log in</button>
    </form>
</div>
