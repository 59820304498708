import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { LoginUserPayload } from './login-user-payload';

@Component({
  selector: 'jt-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.css']
})
export class LoginUserComponent implements OnInit {

  currentLanguage = ''
  LoginForm: FormGroup
  submitted = false
  emailFocused = false
  passwordFocused = false
  returnUrl = '/'
  errorMessage:string = null

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private notification: NotificationService
    ) { }

  ngOnInit(): void {
    this.initForm()
    this.currentLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: LangChangeEvent)=>{
      this.currentLanguage = event.lang
    })
  }

  onSubmit(form: FormGroup) {
    this.submitted = true

    if(form.invalid) {
      if(form.get('email').invalid) {
        document.getElementById('email').focus();
        return
      }
      if(form.get('password').invalid) {
        document.getElementById('password').focus()
        return
      }
      return
    }

    const payload: LoginUserPayload = {
      email: form.get('email').value,
      password: form.get('password').value
    }

    this.auth.loginUser(payload)
    .subscribe(response =>{ 
      this.notification.create(NotificationType.SUCCESS, `${this.translate.instant("Welcome")} ${response.firstName}`)
      if(this.route.snapshot.queryParams['returnUrl'])
      {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl']
      }
      this.router.navigateByUrl(this.returnUrl);
    }, err =>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.errorMessage = this.translate.instant(err.error.message)
    })
  }

  private initForm() {
    this.LoginForm = this.fb.group({
      email: [ null, [Validators.required, Validators.email] ],
      password: [ null, [Validators.required, Validators.minLength(8)] ]
    });
  }

  useLanguage(language: string){
    this.currentLanguage = language
    this.translate.use(language)
  }

  onFocus(input: string) {
    this[`${input}Focused`] = true;
  }

  onBlur(input: string) {
    this[`${input}Focused`] = false;
  }

  /* onInput(){
    this.submitted = false;
  } */

}
