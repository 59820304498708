import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LayoutModule } from '@angular/cdk/layout'

import { AppComponent } from './app.component';
import { RoutingComponent } from './routing/routing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { HeaderComponent } from './layout/header/header.component';
import { LoginUserComponent } from './login-user/login-user.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule } from 'angular-notifier';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { AuthService } from './auth/auth.service';
import { NotificationService } from './noticifation/notification.service';
import { AuthGuard } from './guard/auth.guard';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageTitleComponent } from './shared/page-title/page-title.component';
import { CreateClientComponent } from './create-client/create-client.component';
import { ClientProfileComponent } from './client-profile/client-profile.component';
import { ClientsComponent } from './clients/clients.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CreateSubjectComponent } from './create-subject/create-subject.component';
import { SubjectDataComponent } from './subject-data/subject-data.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { SubjectsArchiveComponent } from './subjects-archive/subjects-archive.component';
import { ServicesComponent } from './services/services.component';
import { CreateBillComponent } from './create-bill/create-bill.component';
import { BillDataComponent } from './bill-data/bill-data.component';
import { BillsComponent } from './bills/bills.component';
import { MyProfileComponent } from './my-profile/my-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SideNavComponent,
    HeaderComponent,
    LoginUserComponent,
    PageTitleComponent,
    CreateClientComponent,
    ClientProfileComponent,
    ClientsComponent,
    CreateSubjectComponent,
    SubjectDataComponent,
    SubjectsComponent,
    SubjectsArchiveComponent,
    ServicesComponent,
    CreateBillComponent,
    BillDataComponent,
    BillsComponent,
    MyProfileComponent
  ],
  imports: [
    BrowserModule,
    RoutingComponent,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    FormsModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right'
        },
        vertical: {
          position: 'top'
        }
      }
    }),
    BrowserAnimationsModule,
    LayoutModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  providers: [
    AuthService,
    NotificationService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
      deps: [AuthService, NotificationService, Router, TranslateService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/','.json');
}
