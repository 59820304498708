import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageUser, User } from '../shared/entities/user';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginUserPayload } from '../login-user/login-user-payload';
import { Router } from '@angular/router';
import { CreateCompanyPayload, CreateIndividualPayload } from '../create-client/create-client-payload';
import { CreateClientComponent } from '../create-client/create-client.component';
import { Subject } from '../shared/entities/subject';
import { Service } from '../shared/entities/service';
import { Bill } from '../shared/entities/bill';
import { changePasswordPayload, updateMyProfilePayload } from '../my-profile/my-profile.component.payload';

@Injectable()
export class AuthService {

    private currentUserSubject: BehaviorSubject<User>;
    currentUser$: Observable<User>;
    readonly localStorageKey = 'currentUser';

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem(this.localStorageKey)));
        this.currentUser$ = this.currentUserSubject.asObservable();
    }

    get currentUser(): User {
        return this.currentUserSubject.value;
    }

    loginUser(payload: LoginUserPayload): Observable<User> {
        const uri = `${environment.apiHost}/login`;
        return this.http.post<any>(uri, payload).pipe(map((response: { user: User, token: string }) => {
            const user: LocalStorageUser = {
                ...response.user,
                token: response.token
            };
            localStorage.setItem(this.localStorageKey, JSON.stringify(user));
            this.currentUserSubject.next(user);
            return response.user
        }));
    }

    createClient(payload: CreateCompanyPayload | CreateIndividualPayload): Observable<User> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/create-client`
        return this.http.post<any>(uri, payload, { headers: header })
    }

    getClient(id: string): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/client/${id}`
        return this.http.get<any>(uri, { headers: header })
    }

    updateClient(payload: CreateCompanyPayload | CreateIndividualPayload, id: string): Observable<User> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/update-client/${id}`
        return this.http.put<any>(uri, payload, { headers: header })
    }

    filterClients(payload): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/clients/filter`
        return this.http.post<any>(uri, payload, { headers: header })
    }

    searchAllClients(filter: string): Observable<Array<User>> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/clients/${filter}/search`
        return this.http.get<any>(uri, { headers: header })
    }

    createSubject(payload): Observable<Subject> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/create-subject`
        return this.http.post<any>(uri, payload, { headers: header })
    }

    getSubject(id: string): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/subject/${id}`
        return this.http.get<any>(uri, { headers: header })
    }

    updateSubject(payload, id: string): Observable<Subject> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/update-subject/${id}`
        return this.http.put<any>(uri, payload, { headers: header })
    }

    filterSubjects(payload): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/subjects/filter`
        return this.http.post<any>(uri, payload, { headers: header })
    }

    filterServices(payload): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/services/filter`
        return this.http.post<any>(uri, payload, { headers: header })
    }

    createService(payload): Observable<Service> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/create-service`
        return this.http.post<any>(uri, payload, { headers: header })
    }

    updateService(payload, id: string): Observable<Service> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/update-service/${id}`
        return this.http.put<any>(uri, payload, { headers: header })
    }

    deleteService(id: string): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/delete-service/${id}`
        return this.http.delete<any>(uri, { headers: header })
    }

    getAvailableServices(payload): Observable<Service[]> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/available-services`
        return this.http.post<any>(uri, payload, { headers: header })
    }

    createBill(payload): Observable<Bill> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/create-bill`
        return this.http.post<any>(uri, payload, { headers: header })
    }

    getBill(id: string): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/bill/${id}`
        return this.http.get<any>(uri, { headers: header })
    }

    updateBill(payload, id: string): Observable<Bill> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/update-bill/${id}`
        return this.http.put<any>(uri, payload, { headers: header })
    }

    deleteBill(id: string): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/delete-bill/${id}`
        return this.http.delete<any>(uri, { headers: header })
    }

    filterBills(payload): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/bills/filter`
        return this.http.post<any>(uri, payload, { headers: header })
    }

    getDashboardBills(): Observable<Bill[]> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/dashboard-bills`
        return this.http.get<any>(uri, { headers: header })
    }

    updateMyProfile(payload: updateMyProfilePayload): Observable<User> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/me/update-profile`
        return this.http.put<any>(uri, payload, { headers: header }).pipe(map((response: { user: User, token: string }) => {
            const user: LocalStorageUser = {
                ...response.user,
                token: response.token
            };
            localStorage.setItem(this.localStorageKey, JSON.stringify(user));
            this.currentUserSubject.next(user);
            return response.user
        }));
    }

    changePassword(payload: changePasswordPayload): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/me/change-password`
        return this.http.put<any>(uri, payload, { headers: header })
    }

    uploadDocument(payload: any, subjectId: string, flowIndex: number): Observable<any> {
        const header = this.authorizationHeader()
        const uri = `${environment.apiHost}/subject/${subjectId}/flow/${flowIndex}/document-upload`;
        return this.http.post<any>(uri, payload, { headers: header });
    }

    logout() {
        localStorage.removeItem(this.localStorageKey);
        this.currentUserSubject.next(null);
        this.router.navigateByUrl('/login');
    }

    authorizationHeader(): HttpHeaders {
        let token = ``
        if (localStorage.getItem(this.localStorageKey)) {
            const loggedIn = JSON.parse(localStorage.getItem(this.localStorageKey))
            token = loggedIn.token
        }
        const header = new HttpHeaders().set(`Authorization`, `Bearer ${token}`)
        return header
    }
}