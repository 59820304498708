export enum Role {
    CLIENT = "CLIENT",
    ADMIN = "ADMIN"
}

export enum Type {
    COMPANY = "COMPANY",
    INDIVIDUAL = "INDIVIDUAL"
}

export interface User {
    _id: string
    type: Type,
    role: Role,
    clientNumber: number,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    jmbg: string,
    companyName: string,
    mb: string,
    pib: string,
    accountNumber: string,
    phone: string,
    mobilePhone: string,
    address: string,
    language: string,
    createdAt: Date,
    createdBy: string
}

export interface LocalStorageUser extends User{
    token: string
}