import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { Bill } from '../shared/entities/bill';
import { Status, Subject } from '../shared/entities/subject';
import * as moment from 'moment'

@Component({
  selector: 'jt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  subjects: Subject[] = []
  bills: Bill[] = []
  noBills = false
  noSubjects = false
  window = window
  moment=moment
  limit = 3
  page = 1

  constructor(
    private auth: AuthService,
    private notification: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getDashboardData()
  }

  getDashboardData() {
    this.getSubjects()
    this.getBills()
  }

  getBills() {
    let payload = {
      limit: this.limit,
      page: this.page,
      paid: false
    }

    this.auth.getDashboardBills().subscribe(response=>{
      this.bills = response
      if(!this.bills.length) {
        this.noBills = true
      }
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })
  }

  getSubjects() {
    let payload = {
      limit: this.limit,
      page: this.page,
      status: [Status.ACTIVE]
    }

    this.auth.filterSubjects(payload).subscribe(response=>{
      this.subjects = response.subjects.reverse()
      if(!this.subjects.length) {
        this.noSubjects = true
      }
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })
  }

}
