import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { DocumentTypes, Status, Subject, Type } from '../shared/entities/subject';
import { User } from '../shared/entities/user';
import * as moment from 'moment'
import { environment } from './../../environments/environment';
import { SelectOption } from '../shared/entities/select-option';
import { Bill } from '../shared/entities/bill';
declare var $: any;


@Component({
  selector: 'app-subject-data',
  templateUrl: './subject-data.component.html',
  styleUrls: ['./subject-data.component.css']
})
export class SubjectDataComponent implements OnInit {

  id = ``
  user: User
  users: Array<User>
  subject: Subject
  bills: Bill[]
  updating = false
  moment = moment
  fileStorageUrl = environment.fileStorageHost
  window = window
  datePickerConfig = {
    dateInputFormat: 'DD/MM/YYYY',
    containerClass: 'theme-dark-blue'
  }
  loading = false
  errorMessage = ''
  updateSubjectForm: FormGroup
  uploadDocumentForm: FormGroup
  uploadDocumentFormInitialized: boolean = false;
  uploading: boolean = false;
  flowIndexUpload: number;
  fileFormData: any;
  subjectTypeOptions: SelectOption[] = []
  subjectStatusOptions: SelectOption[] = []
  documentTypeOptions: SelectOption[] = [];
  flow: FormArray
  newStep(date, description): FormGroup {
    return this.fb.group({
      date: [date ? new Date(date) : null, Validators.required],
      description: [description, Validators.required]
    })
  }

  constructor(
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService,
    private notification: NotificationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id']
      if (!this.id) {
        this.router.navigate(['/subjects'])
        return
      }
      this.auth.getSubject(this.id).subscribe(response => {
        this.subject = response.subject
        this.user = response.user
        this.bills = response.bills
      }, err => {
        if (err.status === 500 || !err.error.message) {
          this.notification.throwServerError()
          this.router.navigate(['/subjects'])
          return
        }
        this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
        this.router.navigate(['/subjects'])
      })
    })

    $('.card-header').click(function () {
      $(this).find(".fa-chevron-down").toggleClass("arrow-flip");
    })

    this.initUploadDocumentForm()
  }


  addStep(): void {
    if (!this.flow! || this.flow.length) {
      this.flow = this.updateSubjectForm.get('flow') as FormArray
    }
    this.flow.push(this.newStep(null, null))
  }

  removeStep(index): void {
    this.flow = this.updateSubjectForm.get('flow') as FormArray
    this.flow.removeAt(index)
  }

  flipArrow(index) {
    $('#arrow' + index).toggleClass('arrow-flip')
  }


  initForm() {
    this.updateSubjectForm = this.fb.group({
      officialSubjectNumber: [this.subject?.officialSubjectNumber ? this.subject.officialSubjectNumber : null],
      shortDescription: [this.subject?.shortDescription ? this.subject.shortDescription : null],
      physicalLocation: [this.subject?.physicalLocation ? this.subject.physicalLocation : null],
      dateOpened: [new Date(this.subject?.dateOpened), Validators.required],
      dateClosed: [this.subject?.dateClosed ? new Date(this.subject?.dateClosed) : null],
      status: [this.subject?.status, Validators.required],
      flow: this.fb.array([]),
      type: [this.subject?.type, Validators.required]
    })
    if (this.subject?.flow.length) {
      this.flow = this.updateSubjectForm.get('flow') as FormArray
      this.subject?.flow.forEach(element => {
        this.flow.push(this.newStep(element.date, element.description))
      })
    }
    this.updating = true
    this.initSubjectTypeOptions()
    this.initSubjectStatusOptions()
  }

  initUploadDocumentForm() {
    this.uploadDocumentForm = this.fb.group({
      name: [null, Validators.required],
      type: [null, Validators.required],
      file: [null, Validators.required],
    });
    this.initDocumentTypeOptions();
    this.uploadDocumentFormInitialized = true;
  }

  onSubmit(form: FormGroup) {
    if (form.invalid) {
      return
    }
    let payload;
    payload = {
      type: form.get('type').value,
      status: form.get('status').value,
      dateOpened: form.get('dateOpened').value
    }
    if (form.get('officialSubjectNumber').value) {
      payload.officialSubjectNumber = form.get('officialSubjectNumber').value
    }
    if (form.get('shortDescription').value) {
      payload.shortDescription = form.get('shortDescription').value
    }
    if (form.get('dateClosed').value) {
      payload.dateClosed = form.get('dateClosed').value
    }
    this.flow = form.get('flow') as FormArray
    payload.flow = form.get('flow').value

    this.loading = true;

    this.auth.updateSubject(payload, this.subject._id).subscribe(response => {
      this.loading = false;
      this.subject = response
      this.notification.create(NotificationType.SUCCESS, this.translate.instant(`Changes saved successfully`))
      this.updating = false
      this.errorMessage = ''
    }, err => {
      this.loading = false;
      if (err.status === 500 || !err.error || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.errorMessage = this.translate.instant(err.error.message)
    })
  }

  uploadDocument(form: FormGroup) {
    if (form.invalid || !this.fileFormData || typeof this.flowIndexUpload !== "number") {
      return;
    }
    this.uploading = true;
    this.fileFormData.append("name", form.get("name").value);
    this.fileFormData.append("type", form.get("type").value);
    this.auth.uploadDocument(this.fileFormData, this.subject._id, this.flowIndexUpload).subscribe(response => {
      if (response) {
        this.subject = response;
        this.notification.create(NotificationType.SUCCESS, this.translate.instant("File uploaded"));
      }
    }, err => {
      if (err.status === 500 || !err.error || !err.error.message) {
        this.notification.throwServerError()
      } else {
        this.notification.create(NotificationType.ERROR, err.error.message);
      }
    });
    this.uploading = false;
    $("#documentUploadModal").modal('hide');
    this.fileFormData = null;
    this.initUploadDocumentForm();
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.fileFormData = new FormData();
      this.fileFormData.append('file', event.target.files[0]);
    }
  }

  archiveSubject() {
    let payload;
    payload = {
      type: this.subject.type,
      status: Status.ARCHIVED,
      dateOpened: this.subject.dateOpened
    }
    if (this.subject.officialSubjectNumber) {
      payload.officialSubjectNumber = this.subject.officialSubjectNumber
    }
    if (this.subject.dateClosed) {
      payload.dateClosed = this.subject.dateClosed
    }
    if (this.subject.flow.length) {
      payload.flow = this.subject.flow
    }

    this.auth.updateSubject(payload, this.subject._id).subscribe(response => {
      this.subject = response
      this.notification.create(NotificationType.SUCCESS, this.translate.instant(`Subject moved to archive`))
      $("#exampleModal").modal('hide')
    }, err => {
      if (err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })

  }

  retrieveSubject() {
    let payload;
    payload = {
      type: this.subject.type,
      status: Status.ACTIVE,
      dateOpened: this.subject.dateOpened
    }
    if (this.subject.officialSubjectNumber) {
      payload.officialSubjectNumber = this.subject.officialSubjectNumber
    }
    if (this.subject.dateClosed) {
      payload.dateClosed = this.subject.dateClosed
    }
    if (this.subject.flow.length) {
      payload.flow = this.subject.flow
    }

    this.auth.updateSubject(payload, this.subject._id).subscribe(response => {
      this.subject = response
      this.notification.create(NotificationType.SUCCESS, this.translate.instant(`Subject retrieved from archive`))
      $("#retrieveModal").modal('hide')
    }, err => {
      if (err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })
  }

  private initSubjectTypeOptions() {
    this.subjectTypeOptions = [
      { value: Type.IZVRSNIPOSTUPAK, label: this.translate.instant(Type.IZVRSNIPOSTUPAK) },
      { value: Type.KRIVICNIPOSTUPAK, label: this.translate.instant(Type.KRIVICNIPOSTUPAK) },
      { value: Type.PARNICNIPOSTUPAK, label: this.translate.instant(Type.PARNICNIPOSTUPAK) },
      { value: Type.PORODICNIODNOSI, label: this.translate.instant(Type.PORODICNIODNOSI) },
      { value: Type.PREKRSAJI, label: this.translate.instant(Type.PREKRSAJI) },
      { value: Type.PRIVREDNISPOR, label: this.translate.instant(Type.PRIVREDNISPOR) },
      { value: Type.RADNIODNOSI, label: this.translate.instant(Type.RADNIODNOSI) },
      { value: Type.UGOVORI, label: this.translate.instant(Type.UGOVORI) },
      { value: Type.UPRAVNIPOSTUPAK, label: this.translate.instant(Type.UPRAVNIPOSTUPAK) },
      { value: Type.UPRAVNISPOR, label: this.translate.instant(Type.UPRAVNISPOR) },
      { value: Type.USTAVNAZALBA, label: this.translate.instant(Type.USTAVNAZALBA) },
      { value: Type.DISCIPLINSKIPOSTUPAK, label: this.translate.instant(Type.DISCIPLINSKIPOSTUPAK) },
      { value: Type.RAZVODBRAKA, label: this.translate.instant(Type.RAZVODBRAKA) },
      { value: Type.OSTAVINSKIPOSTUPAK, label: this.translate.instant(Type.OSTAVINSKIPOSTUPAK) }
    ]
  }

  private initSubjectStatusOptions() {
    this.subjectStatusOptions = [
      { value: Status.ACTIVE, label: this.translate.instant(Status.ACTIVE) },
      { value: Status.PENDING, label: this.translate.instant(Status.PENDING) },
      { value: Status.CLOSED, label: this.translate.instant(Status.CLOSED) }
    ]
  }

  private initDocumentTypeOptions() {
    this.documentTypeOptions = [
      { value: DocumentTypes.RESENJE, label: this.translate.instant(DocumentTypes.RESENJE) },
      { value: DocumentTypes.ZALBA, label: this.translate.instant(DocumentTypes.ZALBA) },
      { value: DocumentTypes.PRIGOVOR, label: this.translate.instant(DocumentTypes.PRIGOVOR) },
      { value: DocumentTypes.TUZBA, label: this.translate.instant(DocumentTypes.TUZBA) },
      { value: DocumentTypes.PRESUDA, label: this.translate.instant(DocumentTypes.PRESUDA) },
      { value: DocumentTypes.ZAKLJUCAK, label: this.translate.instant(DocumentTypes.ZAKLJUCAK) },
      { value: DocumentTypes.UGOVOR, label: this.translate.instant(DocumentTypes.UGOVOR) },
      { value: DocumentTypes.PREDUGOVOR, label: this.translate.instant(DocumentTypes.PREDUGOVOR) },
      { value: DocumentTypes.NAKNADNI_ZAHTEV_POZURNICA, label: this.translate.instant(DocumentTypes.NAKNADNI_ZAHTEV_POZURNICA) },
      { value: DocumentTypes.PUNOMOCJE, label: this.translate.instant(DocumentTypes.PUNOMOCJE) },
      { value: DocumentTypes.PODNESAK, label: this.translate.instant(DocumentTypes.PODNESAK) },
      { value: DocumentTypes.PRECIZIRANI_PODNESAK, label: this.translate.instant(DocumentTypes.PRECIZIRANI_PODNESAK) },
      { value: DocumentTypes.OBRAZLOZENI_PODNESAK, label: this.translate.instant(DocumentTypes.OBRAZLOZENI_PODNESAK) },
      { value: DocumentTypes.OSTALO, label: this.translate.instant(DocumentTypes.OSTALO) }
    ]
  }


}
