<jt-side-nav>
    <jt-page-title [title]="'My profile'"></jt-page-title>
    <div class="container-fluid margin-top-52">
        <div class="row border-bottom rounded">
            <div class="col-6 text-left pt-2 pb-2">
                <span translate>My data</span>
            </div>
            <div class="col-6 text-right pt-2 pb-2">
                <a *ngIf="!updating" (click)="initForm(); initPasswordForm()" href="javascript:void(0)"><i class="fas fa-user-edit main-color"></i></a>
                <a *ngIf="updating" (click)="updating=!updating" href="javascript:void(0)"><i class="fas fa-times main-color"></i></a>
            </div>
        </div>
    </div>
    <div *ngIf="!updating" class="container p-3 p-md-5">
        <div class="row shadow rounded pt-3 pb-3">
            <div class="col-lg-6 border-right">
                <p class="font-weight-bold" translate>Email address</p>
                <p>{{user?.email}}</p>
                <p class="font-weight-bold" translate>Company Name</p>
                <p>{{user?.companyName ? user?.companyName:"/"}}</p>
                <p class="font-weight-bold" translate>Company's identification number</p>
                <p>{{user?.mb ? user?.mb : "/"}}</p>
                <p class="font-weight-bold" translate>Company's PIB</p>
                <p>{{user?.pib ? user?.pib : "/"}}</p>
                <p class="font-weight-bold" translate>Account number</p>
                <p>{{user?.accountNumber ? user?.accountNumber : "/"}}</p>
                <p class="font-weight-bold" translate>Language</p>
                <p *ngIf="user?.language === 'rs'" translate>Serbian</p>
                <p *ngIf="user?.language === 'en'" translate>English</p>
                <p *ngIf="!user?.language">/</p>
            </div>
            <div class="col-lg-6">
                <p class="font-weight-bold" translate>First name</p>
                <p>{{user?.firstName}}</p>
                <p class="font-weight-bold" translate>Last name</p>
                <p>{{user?.lastName}}</p>
                <p class="font-weight-bold" translate>Address</p>
                <p>{{user?.address ? user?.address: "/"}}</p>
                <p class="font-weight-bold" translate>Phone</p>
                <p>{{user?.phone ? user?.phone:"/"}}</p>
                <p class="font-weight-bold" translate>Mobile phone</p>
                <p>{{user?.mobilePhone ? user?.mobilePhone:"/"}}</p>
            </div>
        </div>
    </div>
    <div *ngIf="updating" class="container p-3 p-md-5">
        <form class="row shadow rounded pt-3 pb-3" [formGroup]="updateProfileForm" (ngSubmit)="onSubmit(updateProfileForm)">
            <div class="col-lg-6 border-right">
                <p class="font-weight-bold" translate>Email address</p>
                <p>{{user?.email}}</p>
                <div class="form-group">
                    <label class="font-weight-bold" for="companyName" translate>Company Name<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="companyName"  [ngClass]="{'is-invalid': updateProfileForm.get('companyName') && updateProfileForm.get('companyName').invalid && (updateProfileForm.get('companyName').dirty || updateProfileForm.get('companyName').touched)}" id="companyName">
                    <div *ngIf="updateProfileForm.get('companyName') && updateProfileForm.get('companyName').invalid && (updateProfileForm.get('companyName').dirty || updateProfileForm.get('companyName').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="mb" translate>Company's identification number<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="mb"  [ngClass]="{'is-invalid': updateProfileForm.get('mb') && updateProfileForm.get('mb').invalid && (updateProfileForm.get('mb').dirty || updateProfileForm.get('mb').touched)}" id="mb">
                    <div *ngIf="updateProfileForm.get('mb') && updateProfileForm.get('mb').invalid && (updateProfileForm.get('mb').dirty || updateProfileForm.get('mb').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="pib" translate>Company's PIB<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="pib"  [ngClass]="{'is-invalid': updateProfileForm.get('pib') && updateProfileForm.get('pib').invalid && (updateProfileForm.get('pib').dirty || updateProfileForm.get('pib').touched)}" id="pib">
                    <div *ngIf="updateProfileForm.get('pib') && updateProfileForm.get('pib').invalid && (updateProfileForm.get('pib').dirty || updateProfileForm.get('pib').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="accountNumber" translate>Account number<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="accountNumber"  [ngClass]="{'is-invalid': updateProfileForm.get('accountNumber') && updateProfileForm.get('accountNumber').invalid && (updateProfileForm.get('accountNumber').dirty || updateProfileForm.get('accountNumber').touched)}" id="accountNumber">
                    <div *ngIf="updateProfileForm.get('accountNumber') && updateProfileForm.get('accountNumber').invalid && (updateProfileForm.get('accountNumber').dirty || updateProfileForm.get('accountNumber').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="language" translate>Language<span class="text-danger">*</span></label>
                    <select formControlName="language" class="form-control" [(ngModel)]="currentLanguage">
                        <option *ngFor="let language of languageOptions" [value]="language.value">{{language.label | translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="font-weight-bold" for="firstName" translate>First name<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="firstName"  [ngClass]="{'is-invalid': updateProfileForm.get('firstName') && updateProfileForm.get('firstName').invalid && (updateProfileForm.get('firstName').dirty || updateProfileForm.get('firstName').touched)}" id="firstName">
                    <div *ngIf="updateProfileForm.get('firstName') && updateProfileForm.get('firstName').invalid && (updateProfileForm.get('firstName').dirty || updateProfileForm.get('firstName').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="lastName" translate>Last name<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="lastName"  [ngClass]="{'is-invalid': updateProfileForm.get('lastName') && updateProfileForm.get('lastName').invalid && (updateProfileForm.get('lastName').dirty || updateProfileForm.get('lastName').touched)}" id="lastName">
                    <div *ngIf="updateProfileForm.get('lastName') && updateProfileForm.get('lastName').invalid && (updateProfileForm.get('lastName').dirty || updateProfileForm.get('lastName').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="address" translate>Address<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="address"  [ngClass]="{'is-invalid': updateProfileForm.get('address') && updateProfileForm.get('address').invalid && (updateProfileForm.get('address').dirty || updateProfileForm.get('address').touched)}" id="address">
                    <div *ngIf="updateProfileForm.get('address') && updateProfileForm.get('address').invalid && (updateProfileForm.get('address').dirty || updateProfileForm.get('address').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="phone" translate>Phone<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="phone"  [ngClass]="{'is-invalid': updateProfileForm.get('phone') && updateProfileForm.get('phone').invalid && (updateProfileForm.get('phone').dirty || updateProfileForm.get('phone').touched)}" id="phone">
                    <div *ngIf="updateProfileForm.get('phone') && updateProfileForm.get('phone').invalid && (updateProfileForm.get('phone').dirty || updateProfileForm.get('phone').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="phone" translate>Mobile phone<span class="text-danger">*</span></label>
                    <input class="form-control" formControlName="mobilePhone"  [ngClass]="{'is-invalid': updateProfileForm.get('mobilePhone') && updateProfileForm.get('mobilePhone').invalid && (updateProfileForm.get('mobilePhone').dirty || updateProfileForm.get('mobilePhone').touched)}" id="mobilePhone">
                    <div *ngIf="updateProfileForm.get('mobilePhone') && updateProfileForm.get('mobilePhone').invalid && (updateProfileForm.get('mobilePhone').dirty || updateProfileForm.get('mobilePhone').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex" *ngIf="errorUpdateMessage">
                <div  class="mt-3 mb-3 ml-auto alert alert-danger" role="alert">
                    {{errorUpdateMessage}}
                </div>
            </div>
            <div class="col-12 text-right">
                <button type="submit" class="btn btn-primary" [disabled]="updateProfileForm.invalid" translate>Save changes</button>
            </div>
        </form>
    </div>
    <div *ngIf="updating" class="container p-3 p-md-5">
        <form class="row shadow rounded pt-3 pb-3" [formGroup]="changePasswordForm" (ngSubmit)="onPasswordSubmit(changePasswordForm)">
            <div class="col-12 pb-3 border-bottom" translate>Change password</div>
            <div class="col-12 pt-3">
                <div class="form-group">
                    <label class="font-weight-bold" for="currentPassword" translate>Current password<span class="text-danger">*</span></label>
                    <input class="form-control" type="password" formControlName="currentPassword"  [ngClass]="{'is-invalid': changePasswordForm.get('currentPassword') && changePasswordForm.get('currentPassword').invalid && (changePasswordForm.get('currentPassword').dirty || changePasswordForm.get('currentPassword').touched)}" id="currentPassword">
                    <div *ngIf="changePasswordForm.get('currentPassword') && changePasswordForm.get('currentPassword').invalid && (changePasswordForm.get('currentPassword').dirty || changePasswordForm.get('currentPassword').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="newPassword" translate>New password<span class="text-danger">*</span></label>
                    <input class="form-control" type="password" formControlName="newPassword"  [ngClass]="{'is-invalid': changePasswordForm.get('newPassword') && changePasswordForm.get('newPassword').invalid && (changePasswordForm.get('newPassword').dirty || changePasswordForm.get('newPassword').touched)}" id="newPassword">
                    <div *ngIf="!changePasswordForm.get('newPassword').value && changePasswordForm.get('newPassword').invalid && (changePasswordForm.get('newPassword').dirty || changePasswordForm.get('newPassword').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                    <div *ngIf="changePasswordForm.get('newPassword').value && changePasswordForm.get('newPassword').errors?.minlength && changePasswordForm.get('newPassword').invalid && (changePasswordForm.get('newPassword').dirty || changePasswordForm.get('newPassword').touched)" class="invalid-feedback" translate>
                        Must have at least 8 characters
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="reNewPassword" translate>Repeat new password<span class="text-danger">*</span></label>
                    <input class="form-control" type="password" formControlName="reNewPassword"  [ngClass]="{'is-invalid': changePasswordForm.get('reNewPassword') && changePasswordForm.get('reNewPassword').invalid && (changePasswordForm.get('reNewPassword').dirty || changePasswordForm.get('reNewPassword').touched)}" id="reNewPassword">
                    <div *ngIf="changePasswordForm.get('reNewPassword') && changePasswordForm.get('reNewPassword').invalid && (changePasswordForm.get('reNewPassword').dirty || changePasswordForm.get('reNewPassword').touched)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex" *ngIf="errorPasswordMessage">
                <div  class="mt-3 mb-3 ml-auto alert alert-danger" role="alert">
                    {{errorPasswordMessage}}
                </div>
            </div>
            <div class="col-12 text-right">
                <button type="submit" class="btn btn-primary" [disabled]="changePasswordForm.invalid" translate>Change password</button>
            </div>
        </form>
    </div>
</jt-side-nav>
