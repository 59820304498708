import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { SelectOption } from '../shared/entities/select-option';
import { Type } from '../shared/entities/user';
import { CreateCompanyPayload, CreateIndividualPayload } from './create-client-payload'

@Component({
  selector: 'jt-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {

  ClientForm: FormGroup
  clientType: string = null
  clientTypeOptions: SelectOption[] = []
  errorMessage = ``;
  submitted = false;
  emailFocused = false;
  jmbgFocused = false;
  mbFocused = false;
  firstNameFocused = false;
  lastNameFocused = false;

  constructor(
    private auth: AuthService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private notification: NotificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initClientTypeOptions();
    this.initBaseForm();
  }

  onSumbit(form: FormGroup) {
    this.submitted = true;

    if (form.invalid) {
      if (form.get('type').invalid) {
        document.getElementById('type').focus()
        return
      }
      if (form.get('type').value === "COMPANY") {
        if (form.get('companyName').invalid) {
          document.getElementById('companyName').focus()
          return
        }
        if (form.get('mb').invalid) {
          document.getElementById('mb').focus()
          return
        }
      }
      if (form.get('email').invalid) {
        document.getElementById('email').focus()
        return
      }
      if (form.get('firstName').invalid) {
        document.getElementById('firstName').focus()
        return
      }
      if (form.get('lastName').invalid) {
        document.getElementById('lastName').focus()
        return
      }
      if (form.get('jmbg').invalid) {
        document.getElementById('jmbg').focus()
        return
      }
      if (form.get('address').invalid) {
        document.getElementById('address').focus()
        return
      }
      if (form.get('phone').invalid) {
        document.getElementById('phone').focus()
        return
      }
    }

    let payload: CreateCompanyPayload | CreateIndividualPayload

    if (form.get('type').value === "COMPANY") {
      payload = {
        type: form.get('type').value,
        companyName: form.get('companyName').value,
        mb: form.get('mb').value,
        email: form.get('email').value,
        firstName: form.get('firstName').value,
        lastName: form.get('lastName').value,
        jmbg: form.get('jmbg')?.value,
        address: form.get('address')?.value,
        phone: form.get('phone')?.value
      }
    }

    if (form.get('type').value === "INDIVIDUAL") {
      payload = {
        type: form.get('type').value,
        email: form.get('email').value,
        firstName: form.get('firstName').value,
        lastName: form.get('lastName').value,
        jmbg: form.get('jmbg').value,
        address: form.get('address')?.value,
        phone: form.get('phone')?.value
      }
    }

    this.auth.createClient(payload).subscribe(response => {
      this.notification.create(NotificationType.SUCCESS, this.translate.instant('Client created successfully'))
      this.router.navigate([`/client`], { queryParams: { id: response._id } })
    }, err => {
      if (err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.errorMessage = this.translate.instant(err.error.message)
    })

  }

  onClientTypeChange() {
    this.clientType = (<HTMLSelectElement>document.getElementById('type')).value
    this.submitted = false;
    if (this.clientType) {
      this[`init${this.clientType}form`]()
    }
  }

  private initCOMPANYform() {
    this.ClientForm = this.fb.group({
      type: [this.clientType, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      companyName: [null, [Validators.required]],
      mb: [null, [Validators.required, Validators.pattern(/^[0-9]{8}$/)]],
      firstName: [null, [Validators.required, Validators.minLength(2)]],
      lastName: [null, [Validators.required, Validators.minLength(2)]],
      jmbg: ["", [Validators.pattern(/^[0-9]{13}$/)]],
      address: [null, [Validators.required]],
      phone: [null]
    })
  }

  private initINDIVIDUALform() {
    this.ClientForm = this.fb.group({
      type: [this.clientType, [Validators.required]],
      email: [null, [Validators.email]],
      firstName: [null, [Validators.required, Validators.minLength(2)]],
      lastName: [null, [Validators.required, Validators.minLength(2)]],
      jmbg: [null, [Validators.required, Validators.pattern(/^[0-9]{13}$/)]],
      address: [null, [Validators.required]],
      phone: [null]
    })
  }

  private initBaseForm() {
    this.ClientForm = this.fb.group({
      type: [null, [Validators.required]],
    })
  }

  private initClientTypeOptions() {
    this.clientTypeOptions = [
      { value: Type.INDIVIDUAL, label: this.translate.instant(Type.INDIVIDUAL) },
      { value: Type.COMPANY, label: this.translate.instant(Type.COMPANY) }
    ]
  }

  onFocus(input: string) {
    this[`${input}Focused`] = true;
  }

  onBlur(input: string) {
    this[`${input}Focused`] = false;
  }

}
