import { Routes, RouterModule } from "@angular/router";
import { NgModule } from '@angular/core';
import { DashboardComponent } from "../dashboard/dashboard.component";
import { LoginUserComponent } from "../login-user/login-user.component";
import { CreateClientComponent } from "../create-client/create-client.component";
import { ClientProfileComponent } from "../client-profile/client-profile.component";
import { ClientsComponent } from "../clients/clients.component";
import { AuthGuard } from "../guard/auth.guard";
import { CreateSubjectComponent } from "../create-subject/create-subject.component";
import { SubjectDataComponent } from "../subject-data/subject-data.component";
import { SubjectsComponent } from "../subjects/subjects.component";
import { SubjectsArchiveComponent } from "../subjects-archive/subjects-archive.component";
import { ServicesComponent } from "../services/services.component";
import { CreateBillComponent } from "../create-bill/create-bill.component";
import { BillDataComponent } from "../bill-data/bill-data.component";
import { BillsComponent } from "../bills/bills.component";
import { MyProfileComponent } from "../my-profile/my-profile.component";

const routes: Routes = [
    {path: '', component: DashboardComponent, canActivate:[AuthGuard]},
    {path: 'login',component:LoginUserComponent},
    {path: 'create-client',component:CreateClientComponent, canActivate:[AuthGuard]},
    {path: 'client', component: ClientProfileComponent, canActivate:[AuthGuard], pathMatch:'full'},
    {path: 'clients', component: ClientsComponent, canActivate:[AuthGuard]},
    {path: 'create-subject', component: CreateSubjectComponent, canActivate:[AuthGuard]},
    {path: 'subject', component: SubjectDataComponent, canActivate:[AuthGuard]},
    {path: 'subjects', component:SubjectsComponent, canActivate:[AuthGuard]},
    {path: 'subjects-archive', component:SubjectsArchiveComponent, canActivate:[AuthGuard]},
    {path: 'services', component:ServicesComponent, canActivate:[AuthGuard]},
    {path: 'create-bill', component:CreateBillComponent, canActivate:[AuthGuard]},
    {path: 'bill', component:BillDataComponent, canActivate:[AuthGuard]},
    {path: 'bills', component:BillsComponent, canActivate:[AuthGuard]},
    {path: 'my-profile', component:MyProfileComponent, canActivate:[AuthGuard]}
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    declarations: []
  })
  
  export class RoutingComponent { }