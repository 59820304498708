import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { CreateCompanyPayload, CreateIndividualPayload } from '../create-client/create-client-payload';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { Subject } from '../shared/entities/subject';
import { Type, User } from '../shared/entities/user';
import * as moment from 'moment'
import { Bill } from '../shared/entities/bill';
declare var $: any;

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css']
})
export class ClientProfileComponent implements OnInit {

  user: User
  moment = moment
  subjects: {
    subject: Subject,
    bills: Bill[]
  }[]
  id = ``
  updating = false
  submitted = false
  mbFocused = false
  firstNameFocused = false
  lastNameFocused = false
  jmbgFocused = false
  emailFocused = false
  errorMessage = ``
  updateClientForm: FormGroup
  window = window

  constructor(
    private auth: AuthService,
    private notification: NotificationService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id']
    })
    if (!this.id) {
      this.router.navigate(['/clients'])
      return
    }

    this.auth.getClient(this.id).subscribe(response => {
      this.user = response.user
      this.subjects = response.subjects
    }, err => {
      if (err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        this.router.navigate(['/clients'])
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
      this.router.navigate(['/clients'])
    })
  }

  initForm() {
    if (this.user?.type === Type.INDIVIDUAL) {
      this.updateClientForm = this.fb.group({
        type: [this.user?.type, [Validators.required]],
        firstName: [this.user?.firstName, [Validators.required]],
        lastName: [this.user?.lastName, [Validators.required]],
        jmbg: [this.user?.jmbg, [Validators.required, Validators.pattern(/^[0-9]{13}$/)]],
        email: [this.user?.email, [Validators.email]],
        address: [this.user?.address, [Validators.required]],
        phone: [this.user?.phone ? this.user?.phone : '/']
      })
    }
    if (this.user?.type === Type.COMPANY) {
      this.updateClientForm = this.fb.group({
        type: [this.user?.type, [Validators.required]],
        companyName: [this.user?.companyName, [Validators.required]],
        mb: [this.user?.mb, [Validators.required, Validators.pattern(/^[0-9]{8}$/)]],
        firstName: [this.user?.firstName, [Validators.required]],
        lastName: [this.user?.lastName, [Validators.required]],
        jmbg: [this.user?.jmbg ? this.user?.jmbg : "", [Validators.pattern(/^[0-9]{13}$/)]],
        email: [this.user?.email, [Validators.required, Validators.email]],
        address: [this.user?.address, [Validators.required]],
        phone: [this.user?.phone ? this.user?.phone : '/']
      })
    }
    this.updating = !this.updating
  }

  onSubmit(form: FormGroup) {
    this.submitted = true

    if (form.invalid) {
      return
    }

    let payload: CreateCompanyPayload | CreateIndividualPayload

    if (this.user.type === "COMPANY") {
      payload = {
        type: form.get('type')?.value,
        companyName: form.get('companyName').value,
        mb: form.get('mb').value,
        email: form.get('email').value,
        firstName: form.get('firstName').value,
        lastName: form.get('lastName').value,
        jmbg: form.get('jmbg')?.value,
        address: form.get('address')?.value,
        phone: form.get('phone')?.value
      }
    }

    if (this.user.type === "INDIVIDUAL") {
      payload = {
        type: form.get('type')?.value,
        email: form.get('email').value,
        firstName: form.get('firstName').value,
        lastName: form.get('lastName').value,
        jmbg: form.get('jmbg').value,
        address: form.get('address')?.value,
        phone: form.get('phone')?.value
      }
    }

    this.auth.updateClient(payload, this.user._id).subscribe(response => {
      this.user = response
      this.notification.create(NotificationType.SUCCESS, this.translate.instant(`Changes saved successfully`))
      this.updating = false
      this.errorMessage = ''
    }, err => {
      if (err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.errorMessage = this.translate.instant(err.error.message)
    })

  }

  flipArrow(index) {
    $('#arrow' + index).toggleClass('arrow-flip')
  }

  flipBillArrow(i, r) {
    $('#' + i + 'bill' + r).toggleClass('arrow-flip')
  }

  onFocus(input: string) {
    this[`${input}Focused`] = true;
  }

  onBlur(input: string) {
    this[`${input}Focused`] = false;
  }

}
