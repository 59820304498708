export enum Status {
    ACTIVE = "ACTIVE",
    PENDING = "PENDING",
    CLOSED = "CLOSED",
    ARCHIVED = "ARCHIVED"
}

export enum Type {
    UPRAVNIPOSTUPAK = "UPRAVNI POSTUPAK",
    UPRAVNISPOR = "UPRAVNI SPOR",
    USTAVNAZALBA = "USTAVNA ZALBA",
    KRIVICNIPOSTUPAK = "KRIVICNI POSTUPAK",
    PARNICNIPOSTUPAK = "PARNICNI POSTUPAK",
    RADNIODNOSI = "RADNI ODNOSI",
    UGOVORI = "UGOVORI",
    PORODICNIODNOSI = "PORODICNI ODNOSI",
    PREKRSAJI = "PREKRSAJI",
    IZVRSNIPOSTUPAK = "IZVRSNI POSTUPAK",
    PRIVREDNISPOR = "PRIVREDNI SPOR",
    DISCIPLINSKIPOSTUPAK = "DISCIPLINSKI POSTUPAK",
    RAZVODBRAKA = "RAZVOD BRAKA",
    OSTAVINSKIPOSTUPAK = "OSTAVINSKI POSTUPAK",
    ALL = "ALL"
}

export enum DocumentTypes {
    RESENJE = "RESENJE",
    ZALBA = "ZALBA",
    PRIGOVOR = "PRIGOVOR",
    TUZBA = "TUZBA",
    PRESUDA = "PRESUDA",
    ZAKLJUCAK = "ZAKLJUCAK",
    UGOVOR = "UGOVOR",
    PREDUGOVOR = "PREDUGOVOR",
    NAKNADNI_ZAHTEV_POZURNICA = "NAKNADNI ZAHTEV-POZURNICA",
    PUNOMOCJE = "PUNOMOCJE",
    PODNESAK = "PODNESAK",
    PRECIZIRANI_PODNESAK = "PRECIZIRANI PODNESAK",
    OBRAZLOZENI_PODNESAK = "OBRAZLOZENI PODNESAK",
    OSTALO = "OSTALO"
}

export interface IDocument {
    url: string;
    type: string;
    name: string;
}

export interface IFlow {
    description: string;
    date: string;
    documents?: IDocument[];
}

export interface Subject {
    _id: string,
    subjectNumber: string,
    officialSubjectNumber: string,
    shortDescription: string,
    physicalLocation?: string;
    dateOpened: string,
    dateClosed: string,
    status: Status,
    flow: IFlow[],
    type: Type,
    clientId: string,
    rulesAndPolicyPDF: string,
    contractPDF: string,
    createadAt?: string
}