<jt-side-nav>
    <jt-page-title [title]="'Bill data'"></jt-page-title>
    <div class="container-fluid margin-top-52">
        <div class="row border-bottom rounded">
            <div class="col-6 text-left pt-2 pb-2">
                <span translate>Bill</span> &nbsp; {{bill?.billNumber}}
            </div>
            <div class="col-6 text-right pt-2 pb-2">
                <a *ngIf="!updating" (click)="initForm()" href="javascript:void(0)"><i
                        class="fas fa-pencil-alt main-color"></i></a>
                <a *ngIf="!updating" data-toggle="modal" data-target="#exampleModal" class="ml-3"
                    href="javascript:void(0)"><i class="fas fa-trash-alt main-color"></i></a>
                <a *ngIf="updating" (click)="updating=false" href="javascript:void(0)"><i
                        class="fas fa-times main-color"></i></a>
            </div>
        </div>
    </div>
    <div *ngIf="!updating" class="container p-3 p-md-5">
        <div class="row shadow rounded pt-3 pb-3">
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Client</p>
                <p>
                    <span *ngIf="user && user.type==='INDIVIDUAL'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp;
                        {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp;
                        {{user.jmbg}} &nbsp;&nbsp;</span>
                    <span *ngIf="user && user.type==='COMPANY'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp;
                        {{user.companyName}} &nbsp;&nbsp; {{user.mb}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp;
                        {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; </span>
                </p>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Subject</p>
                <p>
                    <span *ngIf="subject"> &nbsp;&nbsp;#{{ subject.subjectNumber }} &nbsp;&nbsp; {{
                        subject.officialSubjectNumber ? subject.officialSubjectNumber:"" }} &nbsp;&nbsp; {{
                        subject.shortDescription ? subject.shortDescription:"" }} &nbsp;&nbsp; {{ subject.type |
                        translate}} &nbsp;&nbsp; {{ moment(subject.dateOpened).format("DD/MM/YYYYY") }} &nbsp;&nbsp; {{
                        subject.status | translate}} &nbsp;&nbsp;</span>
                </p>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Bill number</p>
                <p>{{ bill?.billNumber }}</p>
                <p class="font-weight-bold" translate>Date of bill creation</p>
                <p>{{moment(bill?.dateMade).format('DD/MM/YYYY')}}</p>
                <p class="font-weight-bold" translate>Date to be paid</p>
                <p>{{moment(bill?.dateToBePaid).format('DD/MM/YYYY')}}</p>
                <p class="font-weight-bold" translate>Bill status</p>
                <p *ngIf="bill?.paid"><i class="fas fa-check text-success"></i>&nbsp;<span translate>Paid</span></p>
                <p *ngIf="!bill?.paid"><i class="fas fa-exclamation-circle text-danger"></i>&nbsp;<span translate>Not
                        paid</span></p>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Services</p>
                <div class="overflof-x"></div>
                <table class="table shadow rounded">
                    <thead>
                        <tr>
                            <th scope="col" translate>Service title</th>
                            <th scope="col" translate>Discount</th>
                            <th scope="col" translate>Price(RSD)</th>
                            <th scope="col" translate>Description</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let serve of bill?.services; let i=index">
                        <tr>
                            <td>{{serve.service.title}}</td>
                            <td>{{ ((1 - serve.discount)*100).toFixed() }} %</td>
                            <td>{{serve.price.toFixed(2)}}</td>
                            <td class="cursor-pointer" [id]="'heading'+i" data-toggle="collapse"
                                [attr.data-target]="'#collapse'+i" aria-expanded="true"
                                [attr.aria-controls]="'collapse'+i" (click)="flipArrow(i)"><span
                                    translate>Description</span>&nbsp;<i [id]="'down'+i"
                                    class="fas fa-chevron-down main-color"></i></td>
                        </tr>
                        <tr class="bg-desc">
                            <td colspan="5" class="p-0">
                                <div class="pr-5 pl-5 mt-3 mb-3 collapse" [id]="'collapse'+i"
                                    [attr.aria-labelledby]="'heading'+i">{{serve.description ? serve.description:"/"}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th scope="col" translate>Total Discount</th>
                            <th scope="col" colspan="3" translate>Total amount</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th scope="col">{{ ((1-bill?.discount)*100).toFixed() }} %</th>
                            <th scope="col" colspan="3">{{ bill?.price.toFixed(2) }} RSD</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Installments</p>
                <div *ngIf="bill?.installments && bill?.installments.length" class="row">
                    <div class="pl-4 pl-lg-5 mb-3" *ngFor="let installment of bill.installments"
                        [ngClass]="{'col-lg-4': bill.installments.length === 3, 'col-lg-6': bill.installments.length === 2}">
                        <p class="font-weight-bold" translate>Price(RSD)</p>
                        <p>{{installment.price.toFixed(2)}}</p>
                        <p class="font-weight-bold" translate>Date of pay</p>
                        <p>{{moment(installment.date).format('DD/MM/YYYY')}}</p>
                    </div>
                </div>
                <p *ngIf="!bill?.installments || !bill?.installments.length">/</p>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Payment method</p>
                <p class="mb-5">{{bill?.paymentMethod | translate}}</p>
                <p class="font-weight-bold" translate>Paid so far</p>
                <p class="mb-5">{{bill?.paidSoFar.toFixed(2)}} RSD</p>
                <p class="font-weight-bold" translate>Payments</p>
                <div *ngIf="bill?.payments && bill?.payments.length" class="row">
                    <div *ngFor="let payment of bill.payments" class="col-lg-4 pl-4 pl-lg-5 mb-3">
                        <p class="font-weight-bold" translate>Date paid</p>
                        <p>{{ moment(payment.date).format('DD/MM/YYYY') }}</p>
                        <p class="font-weight-bold" translate>Amount(RSD)</p>
                        <p>{{ payment.amount.toFixed(2) }}</p>
                    </div>
                </div>
                <p *ngIf="!bill?.payments || !bill?.payments.length">/</p>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Bill PDF</p>
                <p class="mb-5"><a target="_blank" [href]="bill?.billPDF"><i
                            class="fas fa-file-pdf main-color fa-2x"></i></a></p>
                <p class="font-weight-bold" translate>Bill payment order PDF</p>
                <p><a target="_blank" [href]="bill?.paymentOrderPDF"><i
                            class="fas fa-file-pdf main-color fa-2x"></i></a></p>
            </div>
        </div>
    </div>

    <div *ngIf="updating" class="container p-3 p-md-5">
        <form class="row shadow rounded pt-3 pb-3" [formGroup]="createBillForm" (ngSubmit)="onSubmit(createBillForm)">
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Client</p>
                <p>
                    <span *ngIf="user && user.type==='INDIVIDUAL'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp;
                        {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp;
                        {{user.jmbg}} &nbsp;&nbsp;</span>
                    <span *ngIf="user && user.type==='COMPANY'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp;
                        {{user.companyName}} &nbsp;&nbsp; {{user.mb}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp;
                        {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; </span>
                </p>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Subject</p>
                <p>
                    <span *ngIf="subject"> &nbsp;&nbsp;#{{ subject.subjectNumber }} &nbsp;&nbsp; {{
                        subject.officialSubjectNumber ? subject.officialSubjectNumber:"" }} &nbsp;&nbsp; {{ subject.type
                        | translate}} &nbsp;&nbsp; {{ moment(subject.dateOpened).format("DD/MM/YYYYY") }} &nbsp;&nbsp;
                        {{ subject.status | translate}} &nbsp;&nbsp;</span>
                </p>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <p class="font-weight-bold" translate>Bill number</p>
                <p>{{ bill?.billNumber }}</p>
                <div class="form-group">
                    <label for="dateMade" class="font-weight-bold" translate>Date of bill creation</label><span
                        class="text-danger">*</span>
                    <input formControlName="dateMade" type="text" id="dateMade" class="form-control cursor-pointer"
                        [ngClass]="{'is-invalid':createBillForm.get('dateMade').invalid && (createBillForm.get('dateMade').touched || createBillForm.get('dateMade').dirty)}"
                        [bsConfig]="datePickerConfig" bsDatepicker>
                    <div *ngIf="createBillForm.get('dateMade').invalid && (createBillForm.get('dateMade').touched || createBillForm.get('dateMade').dirty)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="dateToBePaid" class="font-weight-bold" translate>Date to be paid</label><span
                        class="text-danger">*</span>
                    <input formControlName="dateToBePaid" type="text" id="dateToBePaid"
                        class="form-control cursor-pointer"
                        [ngClass]="{'is-invalid':createBillForm.get('dateToBePaid').invalid && (createBillForm.get('dateToBePaid').touched || createBillForm.get('dateToBePaid').dirty)}"
                        [bsConfig]="datePickerConfig" bsDatepicker>
                    <div *ngIf="createBillForm.get('dateToBePaid').invalid && (createBillForm.get('dateToBePaid').touched || createBillForm.get('dateToBePaid').dirty)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <p class="font-weight-bold" translate>Bill status</p>
                <p *ngIf="bill?.paid"><i class="fas fa-check text-success"></i>&nbsp;<span translate>Paid</span></p>
                <p *ngIf="!bill?.paid"><i class="fas fa-exclamation-circle text-danger"></i>&nbsp;<span translate>Not
                        paid</span></p>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <div class="form-group">
                    <label for="services" class="font-weight-bold" translate>Services</label><span
                        class="text-danger">*</span>
                    <div class="overflow-x">
                        <table *ngIf="createBillForm.get('services')['controls'].length" class="table shadow rounded">
                            <thead>
                                <tr>
                                    <th scope="col" translate>Service title</th>
                                    <th scope="col" translate>Discount</th>
                                    <th scope="col" translate>Price(RSD)</th>
                                    <th scope="col" translate>Description</th>
                                    <th scope="col" translate>Remove</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="services"
                                *ngFor="let service of createBillForm.get('services')['controls']; let i=index">
                                <tr [formGroupName]="i">
                                    <td>{{createBillForm.get('services').get(i.toString()).get('service').value.title}}
                                    </td>
                                    <td><select class="form-control cursor-pointer"
                                            (change)="onServiceDiscountChange(i)" formControlName="discount"
                                            id="discount">
                                            <option *ngFor="let dis of discount" [value]="dis.value">{{dis.label}}
                                            </option>
                                        </select></td>
                                    <td><input class="form-control" formControlName="price" type="number" id="price"
                                            [ngClass]="{'is-invalid':createBillForm.get('services').get(i.toString()).get('price').invalid && (createBillForm.get('services').get(i.toString()).get('price').touched || createBillForm.get('services').get(i.toString()).get('price').dirty)}">
                                    </td>
                                    <td class="cursor-pointer" [id]="'heading'+i" data-toggle="collapse"
                                        [attr.data-target]="'#collapse'+i" aria-expanded="true"
                                        [attr.aria-controls]="'collapse'+i" (click)="flipArrow(i)"><span
                                            translate>Description</span>&nbsp;<i [id]="'down'+i"
                                            class="fas fa-chevron-down main-color"></i></td>
                                    <td class="text-center"><i (click)="removeService(i)"
                                            class="fas fa-times main-color cursor-pointer"></i></td>
                                </tr>
                                <tr [formGroupName]="i" class="bg-desc">
                                    <td colspan="5" class="p-0">
                                        <div class="pr-5 pl-5 mt-3 mb-3 collapse" [id]="'collapse'+i"
                                            [attr.aria-labelledby]="'heading'+i"><textarea class="form-control"
                                                formControlName="description"></textarea></div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th></th>
                                    <th scope="col" translate>Total Discount</th>
                                    <th scope="col" colspan="3" translate>Total amount</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th scope="col"><select class="form-control cursor-pointer"
                                            formControlName="discount" id="discount">
                                            <option *ngFor="let dis of discount" [value]="dis.value">{{dis.label}}
                                            </option>
                                        </select></th>
                                    <th scope="col" colspan="3">{{ totalAmount.toFixed(2) }} RSD</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div [ngClass]="{'is-invalid': servicesTouched && (!services || !services.length)}">
                        <span class="cursor-pointer" data-toggle="modal" data-target=".bd-example-modal-lg"><i
                                class="fas fa-plus main-color"></i><span translate> Add Service</span></span>
                    </div>
                    <div *ngIf="servicesTouched && (!services || !services.length)" class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <div class="form-group">
                    <label for="installments" class="font-weight-bold" translate>Installments</label>
                    <select class="form-control cursor-pointer" [(ngModel)]="installmentsNumber"
                        [ngModelOptions]="{standalone:true}" (change)="onInstallmentsChange($event)">
                        <option *ngFor="let ins of installmentsSelect" [value]="ins.value">{{ins.label}}</option>
                    </select>
                    <div *ngIf="installmentsNumber > 0 && installments.length" class="row">
                        <div class="pl-lg-4 pl-5 mb-3" formArrayName="installments"
                            *ngFor="let install of createBillForm.get('installments')['controls']; let i=index"
                            [ngClass]="{'col-lg-4':installments.length===3, 'col-lg-6':installments.length===2}">
                            <div class="form-group mt-3" [formGroupName]="i">
                                <label for="price" translate>Price(RSD)</label>
                                <p>{{ createBillForm.get('installments').get(i.toString()).get('price').value }} RSD</p>
                                <label for="date" translate>Date of pay</label><span class="text-danger">*</span>
                                <input formControlName="date" class="form-control cursor-pointer"
                                    [bsConfig]="datePickerConfig" bsDatepicker
                                    [ngClass]="{'is-invalid':createBillForm.get('installments').get(i.toString()).get('date').invalid && (createBillForm.get('installments').get(i.toString()).get('date').touched || createBillForm.get('installments').get(i.toString()).get('date').dirty)}">
                                <div *ngIf="createBillForm.get('installments').get(i.toString()).get('date').invalid && (createBillForm.get('installments').get(i.toString()).get('date').touched || createBillForm.get('installments').get(i.toString()).get('date').dirty)"
                                    class="invalid-feedback" translate>
                                    This field is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 border-bottom mb-3 pb-3">
                <div class="form-group">
                    <label for="paymentMethod" translate>Payment method</label>
                    <select class="form-control cursor-pointer" formControlName="paymentMethod" id="paymentMethod"
                        class="form-control cursor-pointer"
                        [ngClass]="{'is-invalid':createBillForm.get('paymentMethod').invalid && (createBillForm.get('paymentMethod').touched || createBillForm.get('paymentMethod').dirty)}">
                        <option *ngFor="let method of paymenyMethodSelect" [value]="method.value">{{method.label}}
                        </option>
                    </select>
                    <div *ngIf="createBillForm.get('paymentMethod').invalid && (createBillForm.get('paymentMethod').touched || createBillForm.get('paymentMethod').dirty)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="paidSoFar" translate>Paid so far</label>
                    <p class="mb-5">{{(paidSoFar).toFixed(2)}} RSD</p>
                </div>
                <div class="form-group">
                    <label for="payments" translate>Payments</label>
                    <div *ngIf="payments && payments.length" class="row" formArrayName="payments">
                        <div *ngFor="let payment of createBillForm.get('payments')['controls']; let i=index"
                            [formGroupName]="i" class="col-lg-4 mb-3 pl-4 pl-5">
                            <div class="mb-3">
                                <span class="d-block w-100 text-right"><i class="fas fa-times main-color cursor-pointer"
                                        (click)="removePayment(i)"></i></span>
                                <label translate for="date">Date paid</label><span class="text-danger">*</span>
                                <input formControlName="date" class="form-control cursor-pointer"
                                    [bsConfig]="datePickerConfig" bsDatepicker
                                    [ngClass]="{'is-invalid':createBillForm.get('payments').get(i.toString()).get('date').invalid && (createBillForm.get('payments').get(i.toString()).get('date').touched || createBillForm.get('payments').get(i.toString()).get('date').dirty)}">
                                <div *ngIf="createBillForm.get('payments').get(i.toString()).get('date').invalid && (createBillForm.get('payments').get(i.toString()).get('date').touched || createBillForm.get('payments').get(i.toString()).get('date').dirty)"
                                    class="invalid-feedback" translate>
                                    This field is required
                                </div>
                            </div>
                            <div class="mb-3">
                                <label translate for="amount">Amount(RSD)</label><span class="text-danger">*</span>
                                <input formControlName="amount" class="form-control"
                                    [ngClass]="{'is-invalid':createBillForm.get('payments').get(i.toString()).get('amount').invalid && (createBillForm.get('payments').get(i.toString()).get('amount').touched || createBillForm.get('payments').get(i.toString()).get('amount').dirty)}">
                                <div *ngIf="createBillForm.get('payments').get(i.toString()).get('amount').invalid && (createBillForm.get('payments').get(i.toString()).get('amount').touched || createBillForm.get('payments').get(i.toString()).get('amount').dirty)"
                                    class="invalid-feedback" translate>
                                    This field is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <span (click)="addPayment()" class="cursor-pointer"><i class="fas fa-plus main-color"></i><span
                                translate> Add Payment</span></span>
                    </div>
                </div>
                <div class="mt-3 mb-3 alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
                <button class="btn btn-primary mt-3" type="submit"
                    [disabled]="createBillForm.invalid || (!services || !services.length)" translate>Save
                    changes</button>
            </div>
        </form>
    </div>
</jt-side-nav>

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel" translate>Available services</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="servicesTouched = true">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body overflow-auto p-3">
                <table class="table table-hover" *ngIf="!availableEmpty">
                    <thead>
                        <tr>
                            <th scope="col" translate>Service title</th>
                            <th scope="col" translate>Service category</th>
                            <th scope="col" translate>Service price(RSD)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let serve of availableServices" class="cursor-pointer" (click)="addService(serve)"
                            aria-hidden="true" data-dismiss="modal" aria-label="Close">
                            <td>{{ serve.title }}</td>
                            <td>{{ serve.category | translate}}</td>
                            <td>{{ serve.price ? serve.price.toFixed(2)+" RSD":"0 RSD" }}</td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="availableEmpty" class="alert alert-danger" role="alert" translate>No available services for
                    this bill</div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" translate>Delete bill</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p translate>Are you sure you want to delete bill <span> {{bill?.billNumber}}</span> ?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" translate>No</button>
                <button type="button" class="btn btn-primary" (click)="deleteBill()" translate>Yes</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loading" class="loading-bg">
    <mat-spinner [diameter]="75" class="m-auto"></mat-spinner>
</div>