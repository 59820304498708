import { Component, OnInit } from '@angular/core';
import { Bill, Installments, PaymentMethod, Payments, Services } from '../shared/entities/bill';
import { Subject } from '../shared/entities/subject';
import { User } from '../shared/entities/user';
import * as moment from 'moment'
import { Service } from '../shared/entities/service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { ActivatedRoute, RouteConfigLoadEnd, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectNumberOption } from '../shared/entities/select-number-option';
import { environment } from './../../environments/environment';
import { SelectOption } from '../shared/entities/select-option';
declare var $:any

@Component({
  selector: 'app-bill-data',
  templateUrl: './bill-data.component.html',
  styleUrls: ['./bill-data.component.css']
})
export class BillDataComponent implements OnInit {

  billId = ''
  subject: Subject
  user: User
  bill: Bill
  payload = {
    subjectId:'',
    dateMade: '',
    dateToBePaid: '',
    paymentMethod:'',
    price: 0,
    discount:1,
    paidSoFar:0,
    services: []
  }
  moment = moment
  fileStorageUrl = environment.fileStorageHost
  availableServices: Service[]
  availableEmpty = false
  servicesTouched = false
  installmentsSelect: SelectNumberOption[] = []
  discount: SelectNumberOption[] = []
  createBillForm: FormGroup
  payments: FormArray
  services: FormArray
  installments: FormArray
  installmentsNumber = 0
  paymenyMethodSelect: SelectOption[] = []
  paidSoFar = 0
  totalAmount = 0
  updating = false
  errorMessage = ''
  loading = false
  datePickerConfig = {
    dateInputFormat: 'DD/MM/YYYY',
    containerClass: 'theme-dark-blue'
  }
  newPayment(payment:Payments): FormGroup {
    return this.fb.group({
      date: [payment.date ? new Date(payment.date): null,[Validators.required]],
      amount: [payment.amount? payment.amount.toFixed(2): null,[Validators.required]]
    })
  }
  newInstallment(installment: Installments): FormGroup {
    return this.fb.group({
      date: [installment.date ? new Date(installment.date):null,[Validators.required]],
      price: [installment.price.toFixed(2),[Validators.required]]
    })
  }
  newService(services:Services): FormGroup {
    return this.fb.group({
      service:[services.service,[Validators.required]],
      discount: [services.discount,[Validators.required]],
      price: [services.price ? services.price.toFixed(2):0,[Validators.required]],
      description: [services.description ? services.description:""]
    })
  }

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private notification: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params=>{
      this.billId = params['id']
      if(!this.billId) {
        this.router.navigate(['/bills'])
        return
      }
      this.auth.getBill(this.billId).subscribe(response=>{
        this.bill = response.bill
        this.subject = response.subject
        this.user = response.user
        this.availableServices = response.services
        this.installmentsNumber = response.bill.installments ? response.bill.installments.length : 0
        this.paidSoFar = response.bill.paidSoFar
        this.totalAmount = response.bill.price
      }, err=>{
        if(err.status === 500 || !err.error.message) {
          this.notification.throwServerError()
          this.router.navigate(['/bills'])
          return
        }
        this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
        this.router.navigate(['/bills'])
      })
    })
  }

  initForm() {
    this.createBillForm = this.fb.group({
      dateMade: [new Date(this.bill?.dateMade), [Validators.required]],
      dateToBePaid: [new Date(this.bill?.dateToBePaid), [Validators.required]],
      discount:[this.bill.discount,[Validators.required]],
      paymentMethod:[this.bill.paymentMethod,[Validators.required]],
      services: this.fb.array([]),
      payments: this.fb.array([]),
      installments: this.fb.array([])
    })
    if(this.bill?.services.length) {
      this.services = this.createBillForm.get('services') as FormArray
      this.bill?.services.forEach(element=>{
        this.services.push(this.newService(element))
      })
    }
    if(this.bill?.payments && this.bill?.payments.length) {
      this.payments = this.createBillForm.get('payments') as FormArray
      this.bill.payments.forEach(element=>{
        this.payments.push(this.newPayment(element))
      })
    }
    if(this.bill?.installments && this.bill?.installments.length) {
      this.installments = this.createBillForm.get('installments') as FormArray
      this.bill.installments.forEach(element=>{
        this.installments.push(this.newInstallment(element))
      })
    }
    this.initDiscount()
    this.initIntallmentsSelect()
    this.initPaymentMethodSelect()
    this.createBillForm.get('services').valueChanges.subscribe(value=>{
      this.totalAmount=this.calculateSum()
    })
    this.createBillForm.get('discount').valueChanges.subscribe(value=>{
      this.totalAmount=this.calculateSum()
    })
    this.createBillForm.get('payments').valueChanges.subscribe(value=>{
      this.paidSoFar = this.calculatePaidSoFar()
    })
    this.updating = true
  }

  addService(selectedService: Service) {
    this.services = this.createBillForm.get('services') as FormArray
    this.services.push(this.newService({service: selectedService, price: selectedService.price, discount:1, description:""}))
    this.totalAmount = this.calculateSum()
  }

  removeService(index:number) {
    this.services = this.createBillForm.get('services') as FormArray
    this.services.removeAt(index)
    this.totalAmount = this.calculateSum()
  }

  onServiceDiscountChange(index:number) {
    let dis = parseFloat(this.createBillForm.get('services').get(index.toString()).get('discount').value)
    let price = this.createBillForm.get('services').get(index.toString()).get('service').value.price
    let form = this.createBillForm.get('services').get(index.toString()) as FormGroup
    form.controls['price'].setValue((dis*price).toFixed(2))
    this.totalAmount = this.calculateSum()
  }

  onInstallmentsChange(event:Event) {
    this.installmentsNumber = parseInt((<HTMLSelectElement>event.target).value)
    if(this.installments && this.installments.length) {
    this.installments.clear()
    }
    this.installments = this.createBillForm.get('installments') as FormArray
    if(this.installmentsNumber) {
    for(let i=0; i<this.installmentsNumber; i++) {
      this.installments.push(this.newInstallment({date:null, price:this.totalAmount/this.installmentsNumber}))
    }
  }
  }

  addPayment(){
    this.payments = this.createBillForm.get('payments') as FormArray
    this.payments.push(this.newPayment({date: null, amount:null}))
  }

  removePayment(index:number) {
    this.payments = this.createBillForm.get('payments') as FormArray
    this.payments.removeAt(index)
    this.paidSoFar = this.calculatePaidSoFar()
  }

  private initDiscount() {
    this.discount = [
      {value:1, label: '0%'},
      {value:0.99, label: '1%'},
      {value:0.98, label: '2%'},
      {value:0.97, label: '3%'},
      {value:0.96, label: '4%'},
      {value:0.95, label: '5%'},
      {value:0.9, label:'10%'},
      {value:0.85, label:'15%'},
      {value:0.8, label:'20%'},
      {value:0.75, label:'25%'},
      {value:0.7, label:'30%'},
      {value:0.65, label:'35%'},
      {value:0.6, label:'40%'},
      {value:0.55, label:'45%'},
      {value:0.5, label:'50%'}
    ]
  }

  private initIntallmentsSelect() {
    this.installmentsSelect = [
      {value:0, label:'0'},
      {value:2, label:'2'},
      {value:3, label:'3'}
    ]
  }

  private initPaymentMethodSelect() {
    this.paymenyMethodSelect = [
      {value:"CASH", label:this.translate.instant(PaymentMethod.CASH)},
      {value:"CARD", label:this.translate.instant(PaymentMethod.CARD)}
    ]
  }

  flipArrow(index:number) {
    $('#down'+index).toggleClass('arrow-flip')
  }

  calculateSum() {
    let sum:number = 0
    let forms = this.services.controls as FormGroup[]
    forms.forEach(form=>{
      sum+=form.get('price').value ? parseFloat(form.get('price').value):0
    })
    let dis = parseFloat(this.createBillForm.get('discount').value)
    sum = sum*dis
    if(this.installmentsNumber) {
      for(let i=0; i<this.installmentsNumber; i++) {
        let form = this.createBillForm.get('installments').get(i.toString()) as FormGroup
        form.controls['price'].setValue((this.totalAmount/this.installmentsNumber).toFixed(2))
      }
    }
    return sum
  }

  calculatePaidSoFar() {
    let paid:number = 0
    let forms = this.payments.controls as FormGroup[]
    forms.forEach(form=>{
      paid+= form.get('amount').value ? parseFloat(form.get('amount').value) : 0
    })
    return paid
  }

  deleteBill() {
    this.auth.deleteBill(this.bill._id).subscribe(response=>{
      if(response.deleted) {
        this.notification.create(NotificationType.SUCCESS, this.translate.instant('Bill deleted successfully'))
        this.router.navigate(['/bills'])
      }
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })
  }

  onSubmit(form: FormGroup) {
    if(form.invalid || !this.services || !this.services.length) {
      return
    }
    
    this.payload.dateMade = form.get('dateMade').value
    this.payload.dateToBePaid = form.get('dateToBePaid').value
    this.payload.subjectId = this.subject._id
    this.payload.discount = parseFloat(form.get('discount').value)
    this.payload.price = this.totalAmount
    this.payload.paidSoFar = this.paidSoFar
    this.payload.services = form.get('services').value
    this.payload.paymentMethod = form.get('paymentMethod').value
    this.installments = form.get('installments') as FormArray
    if(this.installments.length) {
      this.payload['installments'] = form.get('installments').value
    }
    this.payments = form.get('payments') as FormArray
    if(this.payments.length) {
      this.payload['payments'] = form.get('payments').value
    }

    this.loading = true

    this.auth.updateBill(this.payload, this.bill._id).subscribe(response=>{
      this.loading = false
      this.notification.create(NotificationType.SUCCESS, this.translate.instant("Changes saved successfully"))
      this.bill = response
      this.updating = false
    }, err=>{
      this.loading = false
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.errorMessage = this.translate.instant(err.error.message)
    })
  
  }


}
