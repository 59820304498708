import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { AuthGuard } from '../guard/auth.guard';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { SelectOption } from '../shared/entities/select-option';
import { User } from '../shared/entities/user';
import { changePasswordPayload, updateMyProfilePayload } from './my-profile.component.payload';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  user: User
  updating = false
  updateProfileForm: FormGroup
  changePasswordForm: FormGroup
  languageOptions: SelectOption[]
  currentLanguage = 'rs'
  errorUpdateMessage = ''
  errorPasswordMessage = ''

  constructor(
    private auth: AuthService,
    private notification: NotificationService,
    private translate: TranslateService,
    private router: Router,
    private guard: AuthGuard,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.user = this.auth.currentUser
    if(!this.user){
      this.guard
    }
    if(this.user.language) {
      this.currentLanguage = this.user.language
    }
    this.initLanguageOptions()
  }

  initForm() {
    this.updateProfileForm = this.fb.group({
      companyName: [this.user.companyName,[Validators.required]],
      mb: [this.user.mb,[Validators.required]],
      pib: [this.user.pib,[Validators.required]],
      accountNumber: [this.user.accountNumber,[Validators.required]],
      firstName:[this.user.firstName,[Validators.required]],
      lastName:[this.user.lastName,[Validators.required]],
      language:[this.currentLanguage,[Validators.required]],
      address:[this.user.address,[Validators.required]],
      phone:[this.user.phone,[Validators.required]],
      mobilePhone:[this.user.mobilePhone,[Validators.required]]
    })

    this.updating = true
  }

  initPasswordForm() {
    this.changePasswordForm = this.fb.group({
      currentPassword: [null,[Validators.required]],
      newPassword: [null, [Validators.required, Validators.minLength(8)]],
      reNewPassword: [null, [Validators.required]]
    })
  }

  initLanguageOptions() {
    this.languageOptions = [
      {value:'en', label:"English"},
      {value:'rs', label:"Serbian"}
    ]
  }

  onSubmit(form: FormGroup) {
    if(form.invalid) {
      return
    }

    const payload: updateMyProfilePayload = {
      companyName: form.get('companyName').value,
      mb: form.get('mb').value,
      pib: form.get('pib').value,
      accountNumber: form.get('accountNumber').value,
      firstName: form.get('firstName').value,
      lastName: form.get('lastName').value,
      language: form.get('language').value,
      address: form.get('address').value,
      phone: form.get('phone').value,
      mobilePhone: form.get('mobilePhone').value
    }

    this.auth.updateMyProfile(payload).subscribe(response=>{
      this.user = response
      window.scroll(0,0)
      this.updating = false
      this.errorUpdateMessage = ''
      this.translate.use(this.user.language)
      this.notification.create(NotificationType.SUCCESS, this.translate.instant('Changes saved successfully'))
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.errorUpdateMessage = this.translate.instant(err.error.message)
    })
  }


  onPasswordSubmit(form: FormGroup) {
    if(form.invalid) {
      return
    }

    const payload: changePasswordPayload = {
      currentPassword:form.get('currentPassword').value,
      newPassword: form.get('newPassword').value,
      reNewPassword: form.get('reNewPassword').value
    }

    this.auth.changePassword(payload).subscribe(response=>{
      if(!response.changed) {
        this.errorPasswordMessage = this.translate.instant('Something went wrong')
        return
      }
      this.updating = false
      this.errorPasswordMessage = ''
      this.notification.create(NotificationType.SUCCESS, this.translate.instant('Password changed successfully'))
      
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.errorPasswordMessage = this.translate.instant(err.error.message)
    })
  }

}
