<jt-side-nav>
  <jt-page-title [title]="'Clients'"></jt-page-title>
  <div class="container margin-top-52 p-3 p-md-5">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header p-3 cursor-pointer font-weight-bold d-flex flex-row align-items-center"
            id="headingSearch" data-toggle="collapse" data-target="#collapseSearch" aria-expanded="false"
            aria-controls="collapseSearch" translate>
            <i class="fas fa-search main-color"></i>&nbsp;Search filter&nbsp;<i
              class="fas fa-chevron-down ml-auto main-color"></i>
          </div>
          <div id="collapseSearch" class="collapse" aria-labelledby="headingSearch">
            <div class="card-body pt-0">
              <div class="row justify-content-end pt-1"><a href="javascript:void(0)" (click)="cancelFilter()"
                  class="main-color main-color-hover font-size-note" translate><i
                    class="fas fa-times main-color"></i>&nbsp;&nbsp;cancel filter</a></div>
              <div class="container p-3">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <p class="font-weight-bold" translate>Type of client</p>
                      <i class="fas fa-search"></i>
                      <select class="form-control cursor-pointer search" id="type" (change)="onTypeChange($event)">
                        <option *ngFor="let type of clientTypeOptions" [value]="type.value">{{type.label | translate}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <p class="font-weight-bold" translate>Search client</p>
                      <i class="fas fa-search"></i>
                      <input class="form-control search" [placeholder]="searchPlaceHolder | translate" type="text"
                        id="filterWord" [(ngModel)]="filterWord" (input)="onInputChange()">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div *ngIf="clientType" class="col-12 overflow-x">
        <table *ngIf="clientType==='INDIVIDUAL' && !empty" class="table table-hover shadow rounded">
          <thead>
            <tr>
              <th scope="col"><span translate>Client</span> #</th>
              <th scope="col" translate>First name</th>
              <th scope="col" translate>Last name</th>
              <th scope="col" translate>JMBG</th>
              <th scope="col" translate>Email address</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of users" class="cursor-pointer" [routerLink]="'/client'"
              [queryParams]="{id:user._id}">
              <th scope="row">{{user.clientNumber}}</th>
              <td>{{user.firstName}}</td>
              <td>{{user.lastName}}</td>
              <td>{{user.jmbg}}</td>
              <td>{{user.email}}</td>
            </tr>
          </tbody>
        </table>
        <table *ngIf="clientType==='COMPANY' && !empty" class="table table-hover shadow rounded">
          <thead>
            <tr>
              <th scope="col"><span translate>Client</span> #</th>
              <th scope="col" translate>Company Name</th>
              <th scope="col" translate>MB</th>
              <th scope="col" translate>Representative</th>
              <th scope="col" translate>JMBG</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of users" class="cursor-pointer" [routerLink]="'/client'"
              [queryParams]="{id:user._id}">
              <th scope="row">{{user.clientNumber}}</th>
              <td>{{user.companyName}}</td>
              <td>{{user.mb}}</td>
              <td>{{user.firstName}} {{user.lastName}}</td>
              <td>{{user.jmbg}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="empty" class="col-12">
        <div class="alert alert-danger" role="alert" translate>Unfortunatelly there are no matching clients</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <pagination [totalItems]="totalItems" [itemsPerPage]="itemsParPage" [(ngModel)]="currentPage"
          [boundaryLinks]="true" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
          (pageChanged)="onPageChange($event)" class="ml-auto mr-auto d-flex">
        </pagination>
      </div>
    </div>
  </div>
</jt-side-nav>