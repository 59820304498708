<mat-toolbar class="fixed-top">
    <mat-toolbar-row>
        <div class="w-50">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="(isHandset | async)!.matches">
                <mat-icon class="toogle-sidenav" aria-label="side nav toggle icon">menu</mat-icon>
            </button>
            <img class="brand-logo" src="assets/justify-logo.png">
        </div>
        <div class="w-50 text-right">
            <a href="javascript:void(0)" (click)="window.scroll(0,0)" [routerLink]="'/my-profile'"><i class="fas fa-cog main-color mr-3"></i></a>
            <a href="javascript:void(0)" (click)="userLogout()"><i class="fas fa-sign-out-alt main-color"></i></a>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav #drawer class="sidenav" fixedInViewport = "true" 
    [mode]="(isHandset | async)!.matches ? 'over': 'side'" [opened]="!(isHandset | async)!.matches">
    <nav class="nav flex-column mt-5">
        <a class="nav-link" href="javascript:void(0)" [routerLink]="''" translate>Dashboard</a>
        <a class="nav-link sidenav-menu-toogle mt-3" href="javascript:void(0)" translate>Clients<mat-icon>keyboard_arrow_down</mat-icon></a>
        <div class="sidenav-menu-list">
        <a class="nav-link" href="javascript:void(0)" [routerLink]="'/create-client'" translate>Add Client</a>
        <a class="nav-link" href="javascript:void(0)" [routerLink]="'/clients'" translate>All Clients</a>
        </div>
        <a class="nav-link sidenav-menu-toogle mt-3" href="javascript:void(0)" translate>Subjects<mat-icon>keyboard_arrow_down</mat-icon></a>
        <div class="sidenav-menu-list">
        <a class="nav-link" href="javascript:void(0)" translate [routerLink]="'/create-subject'">Add Subject</a>
        <a class="nav-link" href="javascript:void(0)" translate [routerLink]="'/subjects'">All Subjects</a>
        <a class="nav-link" href="javascript:void(0)" translate [routerLink]="'/subjects-archive'">Archive</a>
        </div>
        <a class="nav-link mt-3" href="javascript:void(0)" [routerLink]="'/bills'" translate>Bills</a>
        <a class="nav-link mt-3" href="javascript:void(0)" translate [routerLink]="'/services'">Services</a>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
