<jt-side-nav>
    <jt-page-title [title]="'Dashboard'"></jt-page-title>
    <div class="container-fluid margin-top-52">
        <div class="row border-bottom">
            <div class="col-12 text-left pt-2 pb-2">
                <span translate>Recently created active subjects</span>
            </div>
        </div>
    </div>
    <div class="container">
        <div *ngIf="!noSubjects" class="row mt-5">
            <div class="col-md-4" *ngFor="let subject of subjects">
                <div class="shadow rounded pb-3 pl-3 pr-3">
                <div class="row justify-content-end"><a href="javascript:void(0)"><i class="fas fa-external-link-alt main-color" (click)="window.scroll(0,0)" [routerLink]="'/subject'" [queryParams]="{id:subject._id}"></i></a></div>
                <p class="font-weight-bold" translate>Subject number</p>
                <p>{{subject.subjectNumber}}</p>
                <p class="font-weight-bold" translate>Date of subject opening</p>
                <p>{{moment(subject.dateOpened).format('DD/MM/YYYY')}}</p>
                <p class="font-weight-bold" translate>Type of subject</p>
                <p>{{subject.type | translate}}</p>
                </div>
            </div>
        </div>
        <div *ngIf="noSubjects" class="row mt-5">
            <div class="alert alert-primary ml-auto mr-auto" role="alert" translate>Add active subjects and they will be shown here</div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row border-bottom">
            <div class="col-12 text-left mt-5 pt-2 pb-2">
                <span translate>The oldest bills that haven't been paid</span>
            </div>
        </div>
    </div>
    <div class="container mb-5">
        <div *ngIf="!noBills" class="row mt-5">
            <div class="col-md-4" *ngFor="let bill of bills">
                <div class="shadow rounded pb-3 pl-3 pr-3">
                <div class="row justify-content-end"><a href="javascript:void(0)"><i class="fas fa-external-link-alt main-color" (click)="window.scroll(0,0)" [routerLink]="'/bill'" [queryParams]="{id:bill._id}"></i></a></div>
                <p class="font-weight-bold" translate>Bill number</p>
                <p>{{bill.billNumber}}</p>
                <p class="font-weight-bold" translate>Date of bill creation</p>
                <p>{{moment(bill.dateMade).format('DD/MM/YYYY')}}</p>
                <p class="font-weight-bold" translate>Total amount</p>
                <p>{{bill.price.toFixed(2)}} RSD</p>
                <p class="font-weight-bold" translate>Paid so far</p>
                <p>{{bill.paidSoFar.toFixed(2)}} RSD</p>
                </div>
            </div>
        </div>
        <div *ngIf="noBills" class="row mt-5">
            <div class="alert alert-primary ml-auto mr-auto" role="alert" translate>Unpaid bills will be shown here</div>
        </div>
    </div>
</jt-side-nav>
