import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { SelectOption } from '../shared/entities/select-option';
import { Status, Subject, Type } from '../shared/entities/subject';
import * as moment from 'moment'
declare var $: any

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css']
})
export class SubjectsComponent implements OnInit {

  filterWord = ``
  subjects: Array<Subject>
  subjectType = ``
  subjectTypeOptions: SelectOption[] = []
  status = [Status.ACTIVE, Status.PENDING, Status.CLOSED]
  empty = false
  searchPlaceHolder = `type subject number or official subject number`
  currentPage = 1
  itemsParPage = 10
  totalItems = 0
  payload = {
    limit: 10,
    page: 1,
    status: this.status
  }
  moment = moment

  constructor(
    private auth: AuthService,
    private notification: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getSubjects()
    this.initSubjectTypeOptions()
    $('.card-header').click(function () {
      $(this).find(".fa-chevron-down").toggleClass("arrow-flip");
    })
  }


  getSubjects() {
    this.empty = false
    this.payload.limit = this.itemsParPage
    this.payload.page = this.currentPage
    delete this.payload['filterWord']
    delete this.payload['type']
    if (this.subjectType) {
      this.payload['type'] = this.subjectType
    }
    if (this.filterWord) {
      this.payload['filterWord'] = this.filterWord
    }

    this.auth.filterSubjects(this.payload).subscribe(response => {
      if (!response.subjects.length) {
        this.empty = true;
      }
      this.subjects = response.subjects
      this.totalItems = response.totalItems
    }, err => {
      if (err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })

  }

  onTypeChange(event: Event) {
    this.subjectType = null
    if ((<HTMLSelectElement>event.target).value) {
      this.subjectType = (<HTMLSelectElement>event.target).value
    }
    this.currentPage = 1
    this.getSubjects()
  }

  onInput() {
    this.currentPage = 1
    this.getSubjects()
  }

  onPageChange(event: any) {
    this.currentPage = event.page
    this.getSubjects()
  }

  cancelFilter() {
    this.subjectType = null;
    (<HTMLSelectElement>document.getElementById('type')).value = this.subjectType
    this.filterWord = ``
    this.getSubjects()
  }

  private initSubjectTypeOptions() {
    this.subjectTypeOptions = [
      { value: '', label: this.translate.instant("ALL") },
      { value: Type.IZVRSNIPOSTUPAK, label: this.translate.instant(Type.IZVRSNIPOSTUPAK) },
      { value: Type.KRIVICNIPOSTUPAK, label: this.translate.instant(Type.KRIVICNIPOSTUPAK) },
      { value: Type.PARNICNIPOSTUPAK, label: this.translate.instant(Type.PARNICNIPOSTUPAK) },
      { value: Type.PORODICNIODNOSI, label: this.translate.instant(Type.PORODICNIODNOSI) },
      { value: Type.PREKRSAJI, label: this.translate.instant(Type.PREKRSAJI) },
      { value: Type.PRIVREDNISPOR, label: this.translate.instant(Type.PRIVREDNISPOR) },
      { value: Type.RADNIODNOSI, label: this.translate.instant(Type.RADNIODNOSI) },
      { value: Type.UGOVORI, label: this.translate.instant(Type.UGOVORI) },
      { value: Type.UPRAVNIPOSTUPAK, label: this.translate.instant(Type.UPRAVNIPOSTUPAK) },
      { value: Type.UPRAVNISPOR, label: this.translate.instant(Type.UPRAVNISPOR) },
      { value: Type.USTAVNAZALBA, label: this.translate.instant(Type.USTAVNAZALBA) },
      { value: Type.DISCIPLINSKIPOSTUPAK, label: this.translate.instant(Type.DISCIPLINSKIPOSTUPAK) },
      { value: Type.RAZVODBRAKA, label: this.translate.instant(Type.RAZVODBRAKA) },
      { value: Type.OSTAVINSKIPOSTUPAK, label: this.translate.instant(Type.OSTAVINSKIPOSTUPAK) }
    ]
  }

}
