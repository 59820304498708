<jt-side-nav>
    <jt-page-title [title]="'Client profile'"></jt-page-title>
    <div class="container-fluid margin-top-52">
        <div class="row border-bottom rounded">
            <div class="col-6 text-left pt-2 pb-2">
                <span translate>Client</span> #{{user?.clientNumber}}
            </div>
            <div class="col-6 text-right pt-2 pb-2">
                <a *ngIf="!updating" (click)="initForm()" href="javascript:void(0)"><i
                        class="fas fa-user-edit main-color"></i></a>
                <a *ngIf="updating" (click)="updating=!updating" href="javascript:void(0)"><i
                        class="fas fa-times main-color"></i></a>
            </div>
        </div>
    </div>
    <div *ngIf="!updating" class="container p-3 p-md-5">
        <div class="row shadow rounded pt-3 pb-3">
            <div class="col-lg-4 border-right">
                <p class="font-weight-bold" translate>Client number</p>
                <p>#{{user?.clientNumber}}</p>
                <p class="font-weight-bold" translate>Type of client</p>
                <p translate>{{user?.type}}</p>
                <div *ngIf="user?.type==='COMPANY'">
                    <p class="font-weight-bold" translate>Company Name</p>
                    <p translate>{{user?.companyName}}</p>
                    <p class="font-weight-bold" translate>Company's identification number</p>
                    <p translate>{{user?.mb}}</p>
                </div>
            </div>
            <div class="col-lg-4 border-right">
                <p *ngIf="user?.type==='INDIVIDUAL'" class="font-weight-bold" translate>First name</p>
                <p *ngIf="user?.type==='COMPANY'" class="font-weight-bold" translate>Representative's first name</p>
                <p>{{user?.firstName}}</p>
                <p *ngIf="user?.type==='INDIVIDUAL'" class="font-weight-bold" translate>Last name</p>
                <p *ngIf="user?.type==='COMPANY'" class="font-weight-bold" translate>Representative's last name</p>
                <p>{{user?.lastName}}</p>
                <p *ngIf="user?.type==='INDIVIDUAL'" class="font-weight-bold" translate>Personal identification number
                </p>
                <p *ngIf="user?.type==='COMPANY'" class="font-weight-bold" translate>Representative's identification
                    number</p>
                <p>{{user?.jmbg ? user?.jmbg:'/'}}</p>
            </div>
            <div class="col-lg-4">
                <p class="font-weight-bold" translate>Email address</p>
                <p>{{user?.email || "/"}}</p>
                <p class="font-weight-bold" translate>Address</p>
                <p>{{user?.address ? user?.address: "/"}}</p>
                <p class="font-weight-bold" translate>Phone</p>
                <p>{{user?.phone ? user?.phone:"/"}}</p>
            </div>
        </div>
    </div>

    <div *ngIf="updating" class="container p-3 p-md-5">
        <form [formGroup]="updateClientForm" (ngSubmit)="onSubmit(updateClientForm)"
            class="row shadow rounded pt-3 pb-3">
            <div class="col-lg-4 border-right">
                <p class="font-weight-bold" translate>Client number</p>
                <p>#{{user?.clientNumber}}</p>
                <p class="font-weight-bold" translate>Type of client</p>
                <p translate>{{user?.type}}</p>
                <div *ngIf="user?.type==='COMPANY'">
                    <div class="form-group">
                        <p class="font-weight-bold"><span translate>Company Name</span><span
                                class="text-danger">*</span></p>
                        <input formControlName="companyName" [value]="updateClientForm.get('companyName')?.value"
                            class="form-control"
                            [ngClass]="{'is-invalid': updateClientForm.get('companyName') && updateClientForm.get('companyName').invalid && (updateClientForm.get('companyName').dirty || updateClientForm.get('companyName').touched || submitted)}"
                            id="companyName">
                        <div *ngIf="updateClientForm.get('companyName') && updateClientForm.get('companyName').invalid && (updateClientForm.get('companyName').dirty || updateClientForm.get('companyName').touched || submitted)"
                            class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <p class="font-weight-bold"><span translate>Company's identification number</span><span
                                class="text-danger">*</span></p>
                        <input formControlName="mb" (focus)="onFocus('mb')" (blur)="onBlur('mb')"
                            [value]="updateClientForm.get('mb')?.value" class="form-control"
                            [ngClass]="{'is-invalid': updateClientForm.get('mb') && updateClientForm.get('mb').invalid && (((updateClientForm.get('mb').dirty || updateClientForm.get('mb').touched) && !mbFocused) || submitted)}"
                            id="mb">
                        <div *ngIf="updateClientForm.get('mb') && updateClientForm.get('mb').invalid && (updateClientForm.get('mb').dirty || updateClientForm.get('mb').touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="updateClientForm.get('mb').errors.required" translate>This field is required
                            </div>
                            <div *ngIf="updateClientForm.get('mb').value && updateClientForm.get('mb').errors.pattern && (!mbFocused || submitted)"
                                translate>Company's identification number must have exactly 8 digits</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 border-right">
                <div class="form-group">
                    <p *ngIf="user?.type==='INDIVIDUAL'" class="font-weight-bold"><span translate>First name</span><span
                            class="text-danger">*</span></p>
                    <p *ngIf="user?.type==='COMPANY'" class="font-weight-bold"><span translate>Representative's first
                            name</span><span class="text-danger">*</span></p>
                    <input formControlName="firstName" [value]="updateClientForm.get('firstName')?.value"
                        class="form-control" (focus)="onFocus('firstName')" (blur)="onBlur('firstName')"
                        [ngClass]="{'is-invalid': updateClientForm.get('firstName').invalid && (((updateClientForm.get('firstName').dirty || updateClientForm.get('firstName').touched) && !firstNameFocused) || submitted)}"
                        id="firstName">
                    <div *ngIf="updateClientForm.get('firstName').invalid && (updateClientForm.get('firstName').dirty || updateClientForm.get('firstName').touched || submitted)"
                        class="invalid-feedback">
                        <div *ngIf="updateClientForm.get('firstName').errors.required" translate>This field is required
                        </div>
                        <div *ngIf="updateClientForm.get('firstName').value && updateClientForm.get('firstName').errors.minlength && (!firstNameFocused || submitted)"
                            translate>First name must have at least two characters</div>
                    </div>
                </div>
                <div class="form-group">
                    <p *ngIf="user?.type==='INDIVIDUAL'" class="font-weight-bold"><span translate>Last name</span><span
                            class="text-danger">*</span></p>
                    <p *ngIf="user?.type==='COMPANY'" class="font-weight-bold"><span translate>Representative's last
                            name</span><span class="text-danger">*</span></p>
                    <input formControlName="lastName" [value]="updateClientForm.get('lastName')?.value"
                        class="form-control" (focus)="onFocus('lastName')" (blur)="onBlur('lastName')"
                        [ngClass]="{'is-invalid': updateClientForm.get('lastName').invalid && (((updateClientForm.get('lastName').dirty || updateClientForm.get('lastName').touched) && !lastNameFocused) || submitted)}"
                        id="lastName">
                    <div *ngIf="updateClientForm.get('lastName').invalid && (updateClientForm.get('lastName').dirty || updateClientForm.get('lastName').touched || submitted)"
                        class="invalid-feedback">
                        <div *ngIf="updateClientForm.get('lastName').errors.required" translate>This field is required
                        </div>
                        <div *ngIf="updateClientForm.get('lastName').value && updateClientForm.get('lastName').errors.minlength && (!lastNameFocused || submitted)"
                            translate>Last name must have at least two characters</div>
                    </div>
                </div>
                <div class="form-group">
                    <p *ngIf="user?.type==='INDIVIDUAL'" class="font-weight-bold"><span translate>Personal
                            identification number</span><span class="text-danger">*</span></p>
                    <p *ngIf="user?.type==='COMPANY'" class="font-weight-bold" translate>Representative's identification
                        number</p>
                    <input formControlName="jmbg" [value]="updateClientForm.get('jmbg')?.value" class="form-control"
                        (focus)="onFocus('jmbg')" (blur)="onBlur('jmbg')"
                        [ngClass]="{'is-invalid': updateClientForm.get('jmbg') && updateClientForm.get('jmbg').invalid && (((updateClientForm.get('jmbg').dirty || updateClientForm.get('jmbg').touched) && !jmbgFocused) || submitted)}"
                        id="jmbg">
                    <div *ngIf="updateClientForm.get('jmbg') && updateClientForm.get('jmbg').invalid && (updateClientForm.get('jmbg').dirty || updateClientForm.get('jmbg').touched || submitted)"
                        class="invalid-feedback">
                        <div *ngIf="updateClientForm.get('jmbg').errors.required" translate>This field is required</div>
                        <div *ngIf="updateClientForm.get('jmbg').value && updateClientForm.get('jmbg').errors.pattern && (!jmbgFocused || submitted)"
                            translate>Personal identification number must have exactly 13 digits</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <p class="font-weight-bold"><span translate>Email address</span></p>
                    <input formControlName="email" [value]="updateClientForm.get('email')?.value" class="form-control"
                        (focus)="onFocus('email')" (blur)="onBlur('email')"
                        [ngClass]="{'is-invalid': updateClientForm.get('email').invalid && (((updateClientForm.get('email').dirty || updateClientForm.get('email').touched) && !emailFocused) || submitted)}"
                        type="email" id="email">
                    <div *ngIf="updateClientForm.get('email').invalid && (updateClientForm.get('email').dirty || updateClientForm.get('email').touched || submitted)"
                        class="invalid-feedback">
                        <div *ngIf="updateClientForm.get('email').errors.required" translate>This field is required
                        </div>
                        <div *ngIf="updateClientForm.get('email').value && updateClientForm.get('email').errors.email && (!emailFocused || submitted)"
                            translate>Invalid Email</div>
                    </div>
                </div>
                <div class="form-group">
                    <p class="font-weight-bold" translate>Address<span class="text-danger">*</span></p>
                    <input formControlName="address" [value]="updateClientForm.get('address')?.value"
                        class="form-control"
                        [ngClass]="{'is-invalid': updateClientForm.get('address') && updateClientForm.get('address').invalid && (updateClientForm.get('address').dirty || updateClientForm.get('address').touched || submitted)}"
                        id="address">
                    <div *ngIf="updateClientForm.get('address') && updateClientForm.get('address').invalid && (updateClientForm.get('address').dirty || updateClientForm.get('address').touched || submitted)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
                <div class="form-group">
                    <p class="font-weight-bold" translate>Phone</p>
                    <input formControlName="phone" [value]="updateClientForm.get('phone')?.value" class="form-control"
                        [ngClass]="{'is-invalid': updateClientForm.get('phone') && updateClientForm.get('phone').invalid && (updateClientForm.get('phone').dirty || updateClientForm.get('phone').touched || submitted)}"
                        id="phone">
                    <div *ngIf="updateClientForm.get('phone') && updateClientForm.get('phone').invalid && (updateClientForm.get('phone').dirty || updateClientForm.get('phone').touched || submitted)"
                        class="invalid-feedback" translate>
                        This field is required
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex" *ngIf="errorMessage">
                <div class="mt-3 mb-3 ml-auto alert alert-danger" role="alert">
                    {{errorMessage}}
                </div>
            </div>
            <div class="col-12 text-right">
                <button type="submit" class="btn btn-primary" [disabled]="updateClientForm.invalid" translate>Save
                    changes</button>
            </div>
        </form>
    </div>

    <div class="container-fluid mt-3 mb-3">
        <div class="row border-bottom">
            <div class="col-12" translate>Subjects</div>
        </div>
        <div class="col-12 text-right">
            <i class="fas fa-circle fa-xs text-primary"></i>&nbsp;<span class="font-size-note"
                translate>Active</span>&nbsp;&nbsp;
            <i class="fas fa-circle fa-xs text-warning"></i>&nbsp;<span class="font-size-note"
                translate>Pending</span>&nbsp;&nbsp;
            <i class="fas fa-circle fa-xs text-secondary"></i>&nbsp;<span class="font-size-note" translate>Closed</span>
        </div>
    </div>
    <div class="container p-3 p-md-5">
        <div class="row">
            <div class="col-12 p-3 p-sm-0">

                <div *ngFor="let item of subjects; let i=index" class="card">
                    <div class="card-header p-3 cursor-pointer font-weight-bold d-flex flex-row align-items-center"
                        (click)="flipArrow(i)" [id]="'heading'+i" data-toggle="collapse"
                        [attr.data-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                        <i class="fas fa-circle"
                            [ngClass]="{'text-secondary': item.subject.status==='CLOSED', 'text-warning': item.subject.status==='PENDING', 'text-primary': item.subject.status==='ACTIVE'}"></i>&nbsp;<span
                            translate>Subject</span>&nbsp;{{item.subject.subjectNumber}}&nbsp;&nbsp;{{item.subject.officialSubjectNumber
                        ? item.subject.officialSubjectNumber : ""}}&nbsp;&nbsp;{{item.subject.shortDescription ?
                        item.subject.shortDescription : ""}}<i [id]="'arrow'+i"
                            class="fas fa-chevron-down ml-auto main-color"></i>
                    </div>
                    <div [id]="'collapse'+i" class="collapse" [attr.aria-labelledby]="'heading'+i">
                        <div class="card-body pt-0">
                            <div class="row justify-content-end pt-1"><a [routerLink]="'/subject'"
                                    [queryParams]="{id:item.subject._id}" href="javascript:void(0)"><i
                                        class="fas fa-external-link-alt main-color"
                                        (click)="window.scroll(0,0)"></i></a></div>
                            <div class="container p-4">
                                <div class="row shadow rounded pt-3 pb-3">
                                    <div class="col-md-6">
                                        <p class="font-weight-bold" translate>Subject number</p>
                                        <p>{{item.subject.subjectNumber}}</p>
                                        <p class="font-weight-bold" translate>Official subject number</p>
                                        <p>{{item.subject.officialSubjectNumber ? item.subject.officialSubjectNumber :
                                            '/'}}</p>
                                        <p class="font-weight-bold" translate>Short description</p>
                                        <p>{{item.subject.shortDescription}}</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="font-weight-bold" translate>Type of subject</p>
                                        <p>{{item.subject.type | translate}}</p>
                                        <p class="font-weight-bold" translate>Date of subject opening</p>
                                        <p>{{moment(item.subject.dateOpened).format('DD/MM/YYYY')}}</p>
                                        <p class="font-weight-bold" translate>Subject status</p>
                                        <p translate>{{item.subject.status}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid p-4 mt-3">
                                <div class="row">
                                    <div class="col-12 border-bottom" translate>Bills</div>
                                </div>
                                <div class="col-12 text-right">
                                    <i class="fas fa-check fa-xs text-success"></i>&nbsp;<span class="font-size-note"
                                        translate>Paid</span>&nbsp;&nbsp;
                                    <i class="fas fa-exclamation-circle fa-xs text-danger"></i>&nbsp;<span
                                        class="font-size-note" translate>Not paid</span>
                                </div>
                            </div>
                            <div class="container-fluid p-4 mb-3">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card" *ngFor="let bill of item.bills; let r=index">
                                            <div class="card-header p-3 cursor-pointer font-weight-bold d-flex flex-row align-items-center"
                                                (click)="flipBillArrow(i,r)" [id]="'heading'+i+r" data-toggle="collapse"
                                                [attr.data-target]="'#collapse'+i+r" aria-expanded="false"
                                                [attr.aria-controls]="'collapse'+i+r">
                                                <i class="fas"
                                                    [ngClass]="{'fa-check fa-xs text-success': bill.paid, 'fa-exclamation-circle fa-xs text-danger':!bill.paid}"></i>&nbsp;<span
                                                    translate>Bill</span>&nbsp;{{bill.billNumber}}&nbsp;<i
                                                    [id]="i+'bill'+r"
                                                    class="fas fa-chevron-down ml-auto main-color"></i>
                                            </div>
                                            <div [id]="'collapse'+i+r" class="collapse"
                                                [attr.aria-labelledby]="'heading'+i+r">
                                                <div class="card-body pt-0">
                                                    <div class="row justify-content-end pt-1"><a
                                                            href="javascript:void(0)"><i
                                                                class="fas fa-external-link-alt main-color"
                                                                (click)="window.scroll(0,0)" [routerLink]="'/bill'"
                                                                [queryParams]="{id:bill._id}"></i></a></div>
                                                    <div class="container p-4">
                                                        <div class="row shadow rounded pt-3 pb-3">
                                                            <div class="col-md-6">
                                                                <p class="font-weight-bold" translate>Bill number</p>
                                                                <p>{{bill.billNumber}}</p>
                                                                <p class="font-weight-bold" translate>Date of bill
                                                                    creation</p>
                                                                <p>{{moment(bill.dateMade).format('DD/MM/YYYY')}}</p>
                                                                <p class="font-weight-bold" translate>Date to be paid
                                                                </p>
                                                                <p>{{moment(bill.dateToBePaid).format('DD/MM/YYYY')}}
                                                                </p>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p class="font-weight-bold" translate>Total amount</p>
                                                                <p>{{ bill.price.toFixed(2) }} RSD</p>
                                                                <p class="font-weight-bold" translate>Paid so far</p>
                                                                <p>{{ bill.paidSoFar.toFixed(2) }} RSD</p>
                                                                <p class="font-weight-bold" translate>Bill status</p>
                                                                <p *ngIf="bill?.paid"><i
                                                                        class="fas fa-check text-success"></i>&nbsp;<span
                                                                        translate>Paid</span></p>
                                                                <p *ngIf="!bill?.paid"><i
                                                                        class="fas fa-exclamation-circle text-danger"></i>&nbsp;<span
                                                                        translate>Not paid</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="btn btn-primary btn-block mt-5" (click)="window.scroll(0,0)"
                                                [routerLink]="'/create-bill'" [queryParams]="{id:item.subject._id}"
                                                translate>Add Bill</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button class="btn btn-primary btn-block mt-5" [routerLink]="'/create-subject'"
                        [queryParams]="{id:user?._id}" translate>Add Subject</button>
                </div>
            </div>
        </div>
    </div>
</jt-side-nav>