import { Component, OnInit } from '@angular/core';
import { Service } from '../shared/entities/service';
import * as moment from 'moment'
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import { SelectOption } from '../shared/entities/select-option';
import { Type } from '../shared/entities/subject';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $:any

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  services: Array<Service>
  filterWord = ``
  serviceCategory = ""
  categories:SelectOption[] = []
  formCategories: SelectOption[] = []
  empty = false
  searchPlaceHolder = `type service title`
  currentPage = 1
  itemsParPage = 10
  totalItems = 0
  payload = {
    limit: 10,
    page: 1
  }
  createServiceForm: FormGroup
  updateServiceForm: FormGroup
  adding = false
  addingErrorMessage = ''
  changePayload = {
    title: '',
    category:''
  }
  updating = false
  updatingErrorMessage = ''
  serviceToUpdate:Service
  index:number
  serviceToDelete:Service

  constructor(
    private auth: AuthService,
    private translate: TranslateService,
    private notification: NotificationService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getServices()
    this.initCategories()
    this.initFormCategories()
    $('.card-header').click(function(){
      $(this).find(".fa-chevron-down").toggleClass("arrow-flip");
    })
  }

  getServices(){
    this.empty = false
    delete this.payload['filterWord']
    delete this.payload['category']
    this.payload.limit = this.itemsParPage
    this.payload.page = this.currentPage
    if(this.serviceCategory) {
      this.payload['category'] = this.serviceCategory
    }
    if(this.filterWord) {
      this.payload['filterWord'] = this.filterWord
    }
    this.auth.filterServices(this.payload).subscribe(response=>{
      if(!response.services.length) {
        this.empty = true
      }
      this.services = response.services
      this.totalItems = response.totalItems
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })
  }

  onTypeChange(event:Event) {
    this.serviceCategory = null
    if((<HTMLSelectElement>event.target).value) {
      this.serviceCategory = (<HTMLSelectElement>event.target).value
    }
    this.currentPage = 1
    this.getServices()
  }

  onInput() {
    this.currentPage = 1
    this.getServices()
  }

  onPageChange(event: any) {
    this.currentPage = event.page
    this.getServices()
  }

  cancelFilter() {
    this.serviceCategory = null;
    (<HTMLSelectElement>document.getElementById('type')).value = this.serviceCategory
    this.filterWord = ``
    this.getServices()
  }

  private initCategories() {
    this.categories = [
      {value: '', label: ''},
      {value: Type.ALL, label: this.translate.instant(Type.ALL)},
      {value: Type.IZVRSNIPOSTUPAK, label: this.translate.instant(Type.IZVRSNIPOSTUPAK)},
      {value: Type.KRIVICNIPOSTUPAK, label: this.translate.instant(Type.KRIVICNIPOSTUPAK)},
      {value: Type.PARNICNIPOSTUPAK, label: this.translate.instant(Type.PARNICNIPOSTUPAK)},
      {value: Type.PORODICNIODNOSI, label: this.translate.instant(Type.PORODICNIODNOSI)},
      {value: Type.PREKRSAJI, label: this.translate.instant(Type.PREKRSAJI)},
      {value: Type.PRIVREDNISPOR, label: this.translate.instant(Type.PRIVREDNISPOR)},
      {value: Type.RADNIODNOSI, label: this.translate.instant(Type.RADNIODNOSI)},
      {value: Type.UGOVORI, label: this.translate.instant(Type.UGOVORI)},
      {value: Type.UPRAVNIPOSTUPAK, label: this.translate.instant(Type.UPRAVNIPOSTUPAK)},
      {value: Type.UPRAVNISPOR, label: this.translate.instant(Type.UPRAVNISPOR)},
      {value: Type.USTAVNAZALBA, label: this.translate.instant(Type.USTAVNAZALBA)}
    ]
  }

  private initFormCategories() {
    this.formCategories = [
      {value: Type.ALL, label: this.translate.instant(Type.ALL)},
      {value: Type.IZVRSNIPOSTUPAK, label: this.translate.instant(Type.IZVRSNIPOSTUPAK)},
      {value: Type.KRIVICNIPOSTUPAK, label: this.translate.instant(Type.KRIVICNIPOSTUPAK)},
      {value: Type.PARNICNIPOSTUPAK, label: this.translate.instant(Type.PARNICNIPOSTUPAK)},
      {value: Type.PORODICNIODNOSI, label: this.translate.instant(Type.PORODICNIODNOSI)},
      {value: Type.PREKRSAJI, label: this.translate.instant(Type.PREKRSAJI)},
      {value: Type.PRIVREDNISPOR, label: this.translate.instant(Type.PRIVREDNISPOR)},
      {value: Type.RADNIODNOSI, label: this.translate.instant(Type.RADNIODNOSI)},
      {value: Type.UGOVORI, label: this.translate.instant(Type.UGOVORI)},
      {value: Type.UPRAVNIPOSTUPAK, label: this.translate.instant(Type.UPRAVNIPOSTUPAK)},
      {value: Type.UPRAVNISPOR, label: this.translate.instant(Type.UPRAVNISPOR)},
      {value: Type.USTAVNAZALBA, label: this.translate.instant(Type.USTAVNAZALBA)}
    ]
  }

  initCreateServiceForm() {
    this.createServiceForm = this.fb.group({
      title:[null,[Validators.required]],
      category:["ALL",[Validators.required]],
      price:[null]
    })
    this.adding = true
    this.addingErrorMessage = ''
  }

  initUpdateServiceForm(service:Service, index:number) {
    this.updateServiceForm = this.fb.group({
      title:[service.title,[Validators.required]],
      category:[service.category,[Validators.required]],
      price:[service.price? service.price.toFixed(2):null]
    })
    this.updating = true
    this.serviceToUpdate = service
    this.index = index
    this.updatingErrorMessage = ''
  }

  onCreateSubmit(form: FormGroup) {
    if(form.invalid) {
      return
    }
    this.addingErrorMessage = ''
    this.changePayload['price'] = 0
    this.changePayload.title = form.get('title').value
    this.changePayload.category = form.get('category').value
    if(form.get('price').value) {
      this.changePayload['price'] = form.get('price').value
    }
    this.auth.createService(this.changePayload).subscribe(response=>{
      this.getServices()
      $("#createService").modal('hide')
      this.adding = false
      this.notification.create(NotificationType.SUCCESS, this.translate.instant('Service created successfully'))
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.addingErrorMessage = this.translate.instant(err.error.message)
    })
  }

  onUpdateSubmit(form: FormGroup) {
    if(form.invalid) {
      return
    }
    this.updatingErrorMessage = ''
    this.changePayload['price'] = 0
    this.changePayload.title = form.get('title').value
    this.changePayload.category = form.get('category').value
    if(form.get('price').value) {
      this.changePayload['price'] = form.get('price').value
    }
    this.auth.updateService(this.changePayload, this.serviceToUpdate._id).subscribe(response=>{
      this.services[this.index] = response
      $("#updateService").modal('hide')
      this.updating = false
      this.notification.create(NotificationType.SUCCESS, this.translate.instant('Changes saved successfully'))
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.updatingErrorMessage = this.translate.instant(err.error.message)
    })
  }

  deleteService() {
    this.auth.deleteService(this.serviceToDelete._id).subscribe(response=>{
      if(response.deleted) {
        this.getServices()
        $("#deleteService").modal('hide')
        this.notification.create(NotificationType.SUCCESS, this.translate.instant('Service deleted successfully'))
      }
    }, err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })
  }

}
