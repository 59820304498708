<jt-side-nav>
    <jt-page-title [title]="'Add Client'"></jt-page-title>
    <div class="container p-3 p-md-5 mt-5">
        <div class="row">
            <div class="col-12">
                <form class="w-100" [formGroup]="ClientForm" (ngSubmit)="onSumbit(ClientForm)">
                    <div class="form-group">
                        <label for="type" translate>Type of client</label><span class="text-danger">*</span>
                        <select class="form-control cursor-pointer" formControlName="type"
                            (change)="onClientTypeChange()"
                            [ngClass]="{'is-invalid': ClientForm.get('type').invalid && (ClientForm.get('type').dirty || ClientForm.get('type').touched || submitted)}"
                            id="type">
                            <option *ngFor="let option of clientTypeOptions" [value]="option.value">{{option.label |
                                translate}}</option>
                        </select>
                        <div *ngIf="ClientForm.get('type').invalid && (ClientForm.get('type').dirty || ClientForm.get('type').touched || submitted)"
                            class="invalid-feedback" translate>
                            Type of client must be either individual or a company
                        </div>
                    </div>
                    <div *ngIf="clientType === 'COMPANY'" class="form-group">
                        <label for="companyName" translate>Company Name</label><span class="text-danger">*</span>
                        <input class="form-control" formControlName="companyName"
                            [ngClass]="{'is-invalid': ClientForm.get('companyName') && ClientForm.get('companyName').invalid && (ClientForm.get('companyName').dirty || ClientForm.get('companyName').touched || submitted)}"
                            id="companyName">
                        <div *ngIf="ClientForm.get('companyName') && ClientForm.get('companyName').invalid && (ClientForm.get('companyName').dirty || ClientForm.get('companyName').touched || submitted)"
                            class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div *ngIf="clientType === 'COMPANY'" class="form-group">
                        <label for="mb" translate>Company's identification number</label><span
                            class="text-danger">*</span>
                        <input class="form-control" formControlName="mb" (focus)="onFocus('mb')" (blur)="onBlur('mb')"
                            [ngClass]="{'is-invalid': ClientForm.get('mb') && ClientForm.get('mb').invalid && (((ClientForm.get('mb').dirty || ClientForm.get('mb').touched) && !mbFocused) || submitted)}"
                            id="mb">
                        <div *ngIf="ClientForm.get('mb') && ClientForm.get('mb').invalid && (ClientForm.get('mb').dirty || ClientForm.get('mb').touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="ClientForm.get('mb').errors.required" translate>This field is required</div>
                            <div *ngIf="ClientForm.get('mb').value && ClientForm.get('mb').errors.pattern && (!mbFocused || submitted)"
                                translate>Company's identification number must have exactly 8 digits</div>
                        </div>
                    </div>
                    <div *ngIf="clientType" class="form-group">
                        <label for="email" translate>Email address</label>
                        <input formControlName="email" class="form-control" (focus)="onFocus('email')"
                            (blur)="onBlur('email')"
                            [ngClass]="{'is-invalid': ClientForm.get('email').invalid && (((ClientForm.get('email').dirty || ClientForm.get('email').touched) && !emailFocused) || submitted)}"
                            type="email" id="email">
                        <div *ngIf="ClientForm.get('email').invalid && (ClientForm.get('email').dirty || ClientForm.get('email').touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="ClientForm.get('email').errors.required" translate>This field is required</div>
                            <div *ngIf="ClientForm.get('email').value && ClientForm.get('email').errors.email && (!emailFocused || submitted)"
                                translate>Invalid Email</div>
                        </div>
                    </div>
                    <div *ngIf="clientType" class="form-group">
                        <label for="firstName" *ngIf="clientType === 'COMPANY'" translate>Representative's first
                            name</label>
                        <label for="firstName" *ngIf="clientType === 'INDIVIDUAL'" translate>First name</label><span
                            class="text-danger">*</span>
                        <input class="form-control" formControlName="firstName" (focus)="onFocus('firstName')"
                            (blur)="onBlur('firstName')"
                            [ngClass]="{'is-invalid': ClientForm.get('firstName').invalid && (((ClientForm.get('firstName').dirty || ClientForm.get('firstName').touched) && !firstNameFocused) || submitted)}"
                            id="firstName">
                        <div *ngIf="ClientForm.get('firstName').invalid && (ClientForm.get('firstName').dirty || ClientForm.get('firstName').touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="ClientForm.get('firstName').errors.required" translate>This field is required
                            </div>
                            <div *ngIf="ClientForm.get('firstName').value && ClientForm.get('firstName').errors.minlength && (!firstNameFocused || submitted)"
                                translate>First name must have at least two characters</div>
                        </div>
                    </div>
                    <div *ngIf="clientType" class="form-group">
                        <label for="lastName" *ngIf="clientType === 'COMPANY'" translate>Representative's last
                            name</label>
                        <label for="lastName" *ngIf="clientType === 'INDIVIDUAL'" translate>Last name</label><span
                            class="text-danger">*</span>
                        <input class="form-control" formControlName="lastName" (focus)="onFocus('lastName')"
                            (blur)="onBlur('lastName')"
                            [ngClass]="{'is-invalid': ClientForm.get('lastName').invalid && (((ClientForm.get('lastName').dirty || ClientForm.get('lastName').touched) && !lastNameFocused) || submitted)}"
                            id="lastName">
                        <div *ngIf="ClientForm.get('lastName').invalid && (ClientForm.get('lastName').dirty || ClientForm.get('lastName').touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="ClientForm.get('lastName').errors.required" translate>This field is required
                            </div>
                            <div *ngIf="ClientForm.get('lastName').value && ClientForm.get('lastName').errors.minlength && (!lastNameFocused || submitted)"
                                translate>Last name must have at least two characters</div>
                        </div>
                    </div>
                    <div *ngIf="clientType" class="form-group">
                        <label for="jmbg" *ngIf="clientType === 'COMPANY'" translate>Representative's identification
                            number</label>
                        <label for="jmbg" *ngIf="clientType === 'INDIVIDUAL'"><span translate>Personal identification
                                number</span><span class="text-danger">*</span></label>
                        <input class="form-control" formControlName="jmbg" (focus)="onFocus('jmbg')"
                            (blur)="onBlur('jmbg')"
                            [ngClass]="{'is-invalid': ClientForm.get('jmbg') && ClientForm.get('jmbg').invalid && (((ClientForm.get('jmbg').dirty || ClientForm.get('jmbg').touched) && !jmbgFocused) || submitted)}"
                            id="jmbg">
                        <div *ngIf="ClientForm.get('jmbg') && ClientForm.get('jmbg').invalid && (ClientForm.get('jmbg').dirty || ClientForm.get('jmbg').touched || submitted)"
                            class="invalid-feedback">
                            <div *ngIf="ClientForm.get('jmbg').errors.required" translate>This field is required</div>
                            <div *ngIf="ClientForm.get('jmbg').value && ClientForm.get('jmbg').errors.pattern && (!jmbgFocused || submitted)"
                                translate>Personal identification number must have exactly 13 digits</div>
                        </div>
                    </div>
                    <div *ngIf="clientType" class="form-group">
                        <label for="address" translate>Address</label><span class="text-danger">*</span>
                        <input class="form-control" formControlName="address"
                            [ngClass]="{'is-invalid': ClientForm.get('address') && ClientForm.get('address').invalid && (ClientForm.get('address').dirty || ClientForm.get('address').touched || submitted)}"
                            id="address">
                        <div *ngIf="ClientForm.get('address') && ClientForm.get('address').invalid && (ClientForm.get('address').dirty || ClientForm.get('address').touched || submitted)"
                            class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div *ngIf="clientType" class="form-group">
                        <label for="phone" translate>Phone</label>
                        <input class="form-control" formControlName="phone"
                            [ngClass]="{'is-invalid': ClientForm.get('phone') && ClientForm.get('phone').invalid && (ClientForm.get('phone').dirty || ClientForm.get('phone').touched || submitted)}"
                            id="phone">
                        <div *ngIf="ClientForm.get('phone') && ClientForm.get('phone').invalid && (ClientForm.get('phone').dirty || ClientForm.get('phone').touched || submitted)"
                            class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div *ngIf="errorMessage" class="mt-5 mb-3 alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                    <button *ngIf="clientType" [disabled]="ClientForm.invalid" class="btn btn-primary mt-3"
                        type="submit" translate>Add Client</button>
                </form>
            </div>
        </div>
    </div>
</jt-side-nav>