<jt-side-nav>
    <jt-page-title [title]="'Add Subject'"></jt-page-title>
    <div class="container margin-top-52 p-3 p-md-5">
        <div class="row">
            <div class="col-12">
                <form class="w-100" [formGroup]="createSubjectForm" (ngSubmit)="onSubmit(createSubjectForm)">
                    <div class="form-group">
                        <label for="clientId" translate>Client</label><span class="text-danger">*</span>
                        <div [ngClass]="{'is-invalid': createSubjectForm.get('clientId').invalid && clientIdTouched}">
                            <i class="fas fa-user-plus cursor-pointer main-color" data-toggle="modal"
                                data-target=".bd-example-modal-lg"></i>
                            <span *ngIf="user && user.type==='INDIVIDUAL'"> &nbsp;&nbsp;#{{user.clientNumber}}
                                &nbsp;&nbsp; {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp;
                                {{user.email}} &nbsp;&nbsp; {{user.jmbg}} &nbsp;&nbsp;</span>
                            <span *ngIf="user && user.type==='COMPANY'"> &nbsp;&nbsp;#{{user.clientNumber}} &nbsp;&nbsp;
                                {{user.companyName}} &nbsp;&nbsp; {{user.mb}} &nbsp;&nbsp; {{user.email}} &nbsp;&nbsp;
                                {{user.firstName}} &nbsp;&nbsp; {{user.lastName}} &nbsp;&nbsp; </span>
                        </div>
                        <div *ngIf="createSubjectForm.get('clientId') && clientIdTouched" class="invalid-feedback"
                            translate>
                            You must select the client
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="type" translate>Type of subject</label><span class="text-danger">*</span>
                        <select class="form-control cursor-pointer" formControlName="type" id="type"
                            [ngClass]="{'is-invalid':createSubjectForm.get('type').invalid && (createSubjectForm.get('type').touched || createSubjectForm.get('type').dirty)}">
                            <option *ngFor="let typeOption of subjectTypeOptions" [value]="typeOption.value">
                                {{typeOption.label | translate}}</option>
                        </select>
                        <div *ngIf="createSubjectForm.get('type').invalid && (createSubjectForm.get('type').touched || createSubjectForm.get('type').dirty)"
                            class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="officialSubjectNumber" translate>Official subject number</label>
                        <input formControlName="officialSubjectNumber" type="text" id="officialSubjectNumber"
                            class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="shortDescription" translate>Short description</label>
                        <input formControlName="shortDescription" type="text" id="shortDescription"
                            class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="physicalLocation" translate>Physical Location</label>
                        <input formControlName="physicalLocation" type="text" id="physicalLocation"
                            class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="dateOpened" translate>Date of subject opening</label><span
                            class="text-danger">*</span>
                        <input formControlName="dateOpened" type="text" id="dateOpened"
                            class="form-control cursor-pointer"
                            [ngClass]="{'is-invalid':createSubjectForm.get('dateOpened').invalid && (createSubjectForm.get('dateOpened').touched || createSubjectForm.get('dateOpened').dirty)}"
                            [bsConfig]="datePickerConfig" [bsValue]="today" bsDatepicker>
                        <div *ngIf="createSubjectForm.get('dateOpened').invalid && (createSubjectForm.get('dateOpened').touched || createSubjectForm.get('dateOpened').dirty)"
                            class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="dateClosed" translate>Date of subject closing</label>
                        <input formControlName="dateClosed" type="text" id="dateClosed"
                            class="form-control cursor-pointer" [bsConfig]="datePickerConfig" bsDatepicker>
                    </div>
                    <div class="form-group">
                        <label for="stauts" translate>Subject status</label><span class="text-danger">*</span>
                        <select class="form-control cursor-pointer" formControlName="status" id="status"
                            [ngClass]="{'is-invalid':createSubjectForm.get('status').invalid && (createSubjectForm.get('status').touched || createSubjectForm.get('status').dirty)}">
                            <option *ngFor="let statusOption of subjectStatusOptions" [value]="statusOption.value">
                                {{statusOption.label | translate}}</option>
                        </select>
                        <div *ngIf="createSubjectForm.get('status').invalid && (createSubjectForm.get('status').touched || createSubjectForm.get('status').dirty)"
                            class="invalid-feedback" translate>
                            This field is required
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="flow" translate>Subject flow</label>
                        <div formArrayName="flow"
                            *ngFor="let step of createSubjectForm.get('flow')['controls'];let i = index">
                            <div class="p-3" [formGroupName]="i">
                                <div class="w-100 d-flex mb-3 border-bottom font-weight-bold"><span
                                        translate>Step</span>&nbsp;{{i+1}}<i class="fas fa-times cursor-pointer ml-auto"
                                        (click)="removeStep(i)"></i></div>
                                <div class="form-group">
                                    <label for="date" translate>Date</label><span class="text-danger">*</span>
                                    <input formControlName="date" class="cursor-pointer form-control"
                                        [bsConfig]="datePickerConfig" bsDatepicker
                                        [ngClass]="{'is-invalid':createSubjectForm.get('flow').get(i.toString()).get('date').invalid && (createSubjectForm.get('flow').get(i.toString()).get('date').touched || createSubjectForm.get('flow').get(i.toString()).get('date').dirty)}">
                                    <div *ngIf="createSubjectForm.get('flow').get(i.toString()).get('date').invalid && (createSubjectForm.get('flow').get(i.toString()).get('date').touched || createSubjectForm.get('flow').get(i.toString()).get('date').dirty)"
                                        class="invalid-feedback" translate>
                                        This field is required
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="description" translate>Description</label><span
                                        class="text-danger">*</span>
                                    <textarea formControlName="description" rows="5" class="form-control"
                                        [ngClass]="{'is-invalid':createSubjectForm.get('flow').get(i.toString()).get('description').invalid && (createSubjectForm.get('flow').get(i.toString()).get('description').touched || createSubjectForm.get('flow').get(i.toString()).get('description').dirty)}"></textarea>
                                    <div *ngIf="createSubjectForm.get('flow').get(i.toString()).get('description').invalid && (createSubjectForm.get('flow').get(i.toString()).get('description').touched || createSubjectForm.get('flow').get(i.toString()).get('description').dirty)"
                                        class="invalid-feedback" translate>
                                        This field is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl-3"><span class="cursor-pointer" (click)="addStep()"><i
                                    class="fas fa-plus main-color"></i><span translate> Add Step</span></span></div>
                    </div>
                    <div class="mt-3 mb-3 alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
                    <button class="btn btn-primary mt-3" type="submit" [disabled]="createSubjectForm.invalid"
                        translate>Add Subject</button>
                </form>
            </div>
        </div>
    </div>
</jt-side-nav>


<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel" translate>Choose client</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="clientIdTouched = true">
                    <span aria-hidden="true"><i class="fas fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body overflow-auto p-3">
                <div class="form-group">
                    <label for="searchClient" translate>Search client</label>
                    <i class="fas fa-search main-color"></i><input type="text" (input)="onUserSearch($event)"
                        [placeholder]="searchClientText | translate" class="form-control search">
                </div>
                <div class="list-group">
                    <a *ngFor="let oneOfUsers of users" href="javascript:void(0)" aria-hidden="true"
                        class="list-group-item list-group-item-action" data-dismiss="modal" aria-label="Close"
                        (click)="addUser(oneOfUsers)">
                        <span *ngIf="oneOfUsers.type==='INDIVIDUAL'">#{{oneOfUsers.clientNumber}}
                            {{oneOfUsers.firstName}} {{oneOfUsers.lastName}} {{oneOfUsers.email}}
                            {{oneOfUsers.jmbg}}</span>
                        <span *ngIf="oneOfUsers.type==='COMPANY'">#{{oneOfUsers.clientNumber}}
                            {{oneOfUsers.companyName}} {{oneOfUsers.mb}} {{oneOfUsers.email}} {{oneOfUsers.firstName}}
                            {{oneOfUsers.lastName}}</span>
                    </a>
                </div>
                <div *ngIf="searchFail" class="alert alert-danger" role="alert" translate>No mathes</div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loading" class="loading-bg">
    <mat-spinner [diameter]="75" class="m-auto"></mat-spinner>
</div>