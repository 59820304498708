import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, NotificationType } from './noticifation/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
   constructor(
     private translate: TranslateService,
     private notification: NotificationService
   ){
     translate.setDefaultLang('rs');
     translate.use('rs');
   }

}

