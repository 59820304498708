import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { NotificationService, NotificationType } from '../noticifation/notification.service';
import * as moment from 'moment'
import { Bill } from '../shared/entities/bill';
import { SelectBooleanOption } from '../shared/entities/select-boolean-option';
declare var $:any

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.css']
})
export class BillsComponent implements OnInit {

  filterWord = ``
  bills: Array<Bill>
  billsPaid = null
  billStatusOptions: SelectBooleanOption[] = []
  empty = false
  searchPlaceHolder = `type bill number, subject number or user number`
  currentPage = 1
  itemsParPage = 10
  totalItems = 0
  payload = {
    limit: 10,
    page: 1,
  }
  moment = moment

  constructor(
    private auth: AuthService,
    private notification: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getBills()
    this.initBillStatusOptions()
    $('.card-header').click(function(){
      $(this).find(".fa-chevron-down").toggleClass("arrow-flip");
    })
  }

  getBills() {
    this.empty = false
    this.payload.limit = this.itemsParPage
    this.payload.page = this.currentPage
    delete this.payload['filterWord']
    delete this.payload['paid']
    if(this.billsPaid === true || this.billsPaid === false ) {
      this.payload['paid'] = this.billsPaid
    }
    if(this.filterWord) {
      this.payload['filterWord'] = this.filterWord
    }
    this.auth.filterBills(this.payload).subscribe(response=>{
      this.bills = response.bills
      if(!this.bills.length) {
        this.empty = true
      }
      this.totalItems = response.totalItems
    },err=>{
      if(err.status === 500 || !err.error.message) {
        this.notification.throwServerError()
        return
      }
      this.notification.create(NotificationType.ERROR, this.translate.instant(err.error.message))
    })
  }

  onTypeChange(event:Event) {
    this.billsPaid = null
    if((<HTMLSelectElement>event.target).value === "true") {
      this.billsPaid = true
    }
    if((<HTMLSelectElement>event.target).value === "false") {
      this.billsPaid = false
    }
    console.log(this.billsPaid)
    this.currentPage = 1
    this.getBills()
  }

  onInput() {
    this.currentPage = 1
    this.getBills()
  }

  onPageChange(event:any) {
    this.currentPage = event.page
    this.getBills()
  }

  cancelFilter() {
    this.billsPaid = null;
    (<HTMLSelectElement>document.getElementById('paid')).value = this.billsPaid
    this.filterWord = ``
    this.getBills()
  }

  private initBillStatusOptions() {
    this.billStatusOptions = [
      {value: null, label:''},
      {value: true, label:'Paid'},
      {value: false, label:'Not paid'}
    ]
  }

}
